<div class="footer">
    <div class="responsive-width">
        <div class="one">
            <p class="footer-txt"><strong>Alianzas estratégicas para
                ayudarte a materializar tu invención.</strong></p>
            <img class="pa-t-20" src="/assets/images/logos/full_logo_im_tech.png">
            
        </div>
        <div class="two">
            <ul class="features-box">
                <p class="footer-txt pa-b-10"><strong>Nosotros</strong></p>
                <li><a class="info footer-normal-txt pa-t-10" routerLink="/about-us">¿Quiénes somos?</a></li>
                <li><a class="info footer-normal-txt pa-t-10" routerLink="/faq">Preguntas frecuentes</a></li>
                <li><a class="info footer-normal-txt pa-t-10" routerLink="/terms-and-conditions">Términos y Condiciones</a></li>
                <li><a class="info footer-normal-txt pa-t-10" routerLink="/privacy-policies">Aviso de privacidad</a></li>
            </ul>
            <ul class="features-box">
                <p class="footer-txt pa-b-10"><strong>Afiliados</strong></p>
                <!-- <li><a class="footer-normal-txt pa-t-10" routerLink="/preregister">Registrar Mi Negocio</a></li> -->
                <li><a class="footer-normal-txt pa-t-10" routerLink="/login">Iniciar Sesión</a></li>
            </ul>
        </div>
        <div class="line"></div>
        <div class="three">
            <ul>
                <li><p class="footer-txt pa-b-10"><strong>Contáctanos</strong></p></li>
                <li><a class="footer-normal-txt pa-t-10" href="mailto:contacto@enbibo.com">ventas@ingmulti.com</a></li>
                <li><p class="footer-normal-txt pa-t-10">Llámanos <!-- o envíanos un WhatsApp -->al 614 417 81 89</p></li>
                <li><p class="footer-normal-txt pa-t-10">Síguenos en 
                        <a class="social" href="https://www.facebook.com/" target="_blank">
                            <i class="fab fa-facebook-f"></i></a>
                        <a class="social" href="https://www.instagram.com/"  target="_blank">
                            <i class="fab fa-instagram" target="_blank"></i></a>
                        <a class="social" href="https://twitter.com" target="_blank">
                            <i class="fab fa-twitter"></i></a>
                        <a class="social" href="https://www.youtube.com/" target="_blank">
                            <i class="fab fa-youtube"></i></a>
                    </p>
                </li>
                <li class="pa-t-30">
                    <!-- <img class="app-img" src="/assets/images/logo-app.png"> -->
                    <a href="https://apps.apple.com/mx/app/gawi/id1620592179?l=en"  target="_blank">
                        <!-- <img class="app-img  max-btn-animate" src="/assets/images/app-store.png"> -->
                        <img class="app-img  max-btn-animate" src="/assets/images/components/footer/app_store.png">
                    </a>
                </li>
                <li class="pa-t-30">
                    <!-- <img class="app-img" src="/assets/images/logo-app.png"> -->
                    <a href="https://play.google.com/store/apps/details?id=com.imtech.gawi.gas"  target="_blank">
                        <!-- <img class="app-img  max-btn-animate" src="/assets/images/app-store.png"> -->
                        <img class="app-img  max-btn-animate" src="/assets/images/components/footer/play-store.png">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="small-footer reading-footer">
    <div class="responsive-width">
        <p class="pa-t-10 pa-b-10">2022 ingmulti. Todos los derechos reservados</p>
    </div>
</div>