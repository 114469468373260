import { RouteInfo } from './sidebar-production.metadata';

export const PRODUCTIONMENU: RouteInfo[] = [
    {
        path: '/dashboard-production/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-production/departure-orders',
        title: 'Órdenes de salida',
        icon: 'fas fa-file-check',
        class: '',
        extralink: false,
        submenu: []
    },
];
