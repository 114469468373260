export const deployConf = {
    // another keys
    captchaApiKeyGoogleHTML: '6LdHnJUaAAAAAALfAXpfFpZfeH2q8cOijBsXrpZM',
    captchaApiKeyGoogle: '6LdHnJUaAAAAACRIDq3xMAAmPzvYiEu42Z73MYaj',
    mapBoxApiKey: 'pk.eyJ1IjoiZW5iaWJvbWFwYm94IiwiYSI6ImNrcWEwb2FxMDA5Z3Uybm55cHUydnE4dGQifQ.obI_ID6dhPSnDv3fDNpRKg',
    // testing server
    // apiUrl: "http://ingmulti.dyndns.org:3001/",  // external network
    // apiUrl: "http://192.168.1.100:3002/",        // inner network
    // apiUrl: "http://192.168.1.191:3000/",          // hipsy inner network
    // apiUrl: "http://localhost:3002/",            // local host
    //apiUrl: "https://api-test.gawi.mx/", //test server
    // production server
    
    apiUrl: "https://api.gawi.mx/",
    
    // apiUrl: "https://api.gawi.mx/",

    // demo server
    //apiUrl: "http://api-demo.gawi.mx/",
    //Test
}
