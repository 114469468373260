import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CommonModule,
    LocationStrategy,
    HashLocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { ToastrModule } from 'ngx-toastr';
import { DialogModule, MultiSelectModule, SidebarModule, ToggleButtonModule } from 'primeng';
import { MatSelectModule } from '@angular/material/select';



/* import { NgxMaskModule, IConfig } from 'ngx-mask'; 
const maskConfig: Partial<IConfig> = {
    validation: false,
  }; */

/**
 * App Routes
 */
import { Approutes } from './app-routing.module';

/**
 * Layouts y Componentes
 */
// Publicos
import { BlankComponent } from './layouts/blank/blank.component';
import { FullPublicComponent } from './layouts/full-public/full-public.component';
// Administracion 
import { FullAdminComponent } from './layouts/full-admin/full-admin.component';
import { NavigationAdminComponent } from './components/dashboards/admin/header-navigation-admin/navigation-admin.component';
import { SidebarAdminComponent } from './components/dashboards/admin/sidebar-admin/sidebar-admin.component';
// Organizacion
import { FullOrganizationComponent } from './layouts/full-organization/full-organization.component';
import { NavigationOrganizationComponent } from './components/dashboards/organization/header-navigation-organization/navigation-organization.component';
import { SidebarOrganizationComponent } from './components/dashboards/organization/sidebar-organization/sidebar-organization.component';
// Almacen
import { FullWarehouseComponent } from './layouts/full-warehouse/full-warehouse.component';
import { NavigationWarehouseComponent } from './components/dashboards/warehouse/header-navigation-warehouse/navigation-warehouse.component';
import { SidebarWarehouseComponent } from './components/dashboards/warehouse/sidebar-warehouse/sidebar-warehouse.component';
// Contabilidad
import { FullCounterComponent } from './layouts/full-counter/full-counter.component';
import { NavigationCounterComponent } from './components/dashboards/counter/header-navigation-counter/navigation-counter.component';
import { SidebarCounterComponent } from './components/dashboards/counter/sidebar-counter/sidebar-counter.component';
// Produccion
import { FullProductionComponent } from './layouts/full-production/full-production.component';
import { NavigationProductionComponent } from './components/dashboards/production/header-navigation-production/navigation-production.component';
import { SidebarProductionComponent } from './components/dashboards/production/sidebar-production/sidebar-production.component';
// Otros
import { SpinnerComponent } from './components/public/loading/spinner.component';
import { NavigationPublicComponent } from './components/public/header-navigation-public/navigation-public.component';
import { FooterPublicComponent } from './components/public/footer-public/footer-public.component';

import { AppComponent } from './app.component';

import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { PERFECT_SCROLLBAR_CONFIG,PerfectScrollbarConfigInterface,PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCaptchaModule } from 'ngx-captcha';


const maskConfig: Partial<IConfig> = {
    validation: false,
  };


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 1,
    wheelPropagation: true,
    minScrollbarLength: 20
};


/**
 * Vistas publicas
 */
import { HomeComponent } from './views/public/home/home.component';
import { LoginComponent } from './views/public/login/login.component';
import { TermsConditionsComponent } from './views/public/terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './views/public/about-us/about-us.component';
import { FaqComponent } from './views/public/faq/faq.component';
import { RecoveryPasswordComponent } from './views/public/recovery-password/recovery-password.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { PrivacyPoliciesComponent } from './views/public/privacy-policies/privacy-policies.component';

import { NotfoundComponent } from './views/public/404/not-found.component';
/* import { CardComponent } from './components/card/card.component'; */

@NgModule({
    declarations: [
        AppComponent,
        
        /**
         * Layouts
         */
        BlankComponent,
        FullPublicComponent,
        FullAdminComponent,
        FullOrganizationComponent,
        FullWarehouseComponent,
        FullCounterComponent,
        FullProductionComponent,
        
        /**
         * Componentes
         */
        NavigationPublicComponent,       // public
        FooterPublicComponent,
        NavigationAdminComponent,        // administrador
        SidebarAdminComponent,
        NavigationOrganizationComponent, // organizacion
        SidebarOrganizationComponent,
        NavigationWarehouseComponent,    // almacenista
        SidebarWarehouseComponent,
        NavigationCounterComponent,      // contabilidad
        SidebarCounterComponent,
        NavigationProductionComponent,   // produccion
        SidebarProductionComponent,
        BreadcrumbComponent,             // otros
        SpinnerComponent,
        
        /**
         * Vistas publicas
         */
        NotfoundComponent,
        HomeComponent,
        LoginComponent,
        TermsConditionsComponent,
        AboutUsComponent,
        PrivacyPoliciesComponent,
        FaqComponent,
        RecoveryPasswordComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot(),
        SlimLoadingBarModule.forRoot(),
        RouterModule.forRoot(Approutes, { useHash: false }),
        PerfectScrollbarModule,
        Ng2SearchPipeModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxMaskModule.forRoot(maskConfig),
        CarouselModule,
        NgxCaptchaModule,
        // NgxMaskModule.forRoot(maskConfig),
        MultiSelectModule,
        SidebarModule,
        ToggleButtonModule,
        DialogModule,
        MatSelectModule,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
