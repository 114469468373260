<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <li>
            <div class="user-profile m-t-20" ngbDropdown>
                <div class="user-pic">
                    <img src="assets/images/users/no-user-image.jpg" alt="users" class="rounded-circle img-fluid" />
                </div>
                <div class="user-content hide-menu m-t-10">
                    <h5 class="m-b-10 user-name font-medium text-capitalize">
                        <div>{{dataSessionService.user.firstName}}</div>
                        <div>{{dataSessionService.user.lastName + ' ' + dataSessionService.user.mothersLastName}}</div>
                    </h5>
                </div>
            </div>
        </li>
        <!-- 
          -- Menu de primer nivel
          -->
        <li class="sidebar-item" 
            *ngFor="let sidebarnavItem of sidebarNavItemsProduction"
            [class.selected]="showMenu === sidebarnavItem.title && sidebarnavItem.submenu.length != 0"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'selected active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </div>
            <a  class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]"
                *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"
            >
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
            <!-- 
              -- Menu de segundo nivel
              -->
            <!-- <ul aria-expanded="false"
                class="collapse ma-l-10p"
                *ngIf="sidebarnavItem.submenu.length > 0"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }"
            >
                <li class="sidebar-item"
                    *ngFor="let sidebarnavSubItem of sidebarnavItem.submenu"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'selected active'"
                >
                    <a  class="sidebar-link"
                        [routerLink]="sidebarnavSubItem.submenu.length > 0 ? null : [sidebarnavSubItem.path]"
                        [routerLinkActive]="sidebarnavSubItem.submenu.length > 0 ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class]"
                        *ngIf="!sidebarnavSubItem.extralink;"
                        (click)="addActiveClass(sidebarnavSubItem.title)"
                    >
                        <i [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title}}</span>
                    </a> -->
                    <!-- 
                      -- Menu de tercer nivel
                      -->
                    <!-- <ul aria-expanded="false"
                        class="collapse second-level"
                        *ngIf="sidebarnavSubItem.submenu.length > 0"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }"
                    >
                        <li class="sidebar-item"
                            *ngFor="let sidebarnavSubsubItem of sidebarnavSubItem.submenu"
                            routerLinkActive="active"
                            [ngClass]="[sidebarnavSubsubItem.class]"
                        >
                            <a  class="sidebar-link"
                                [routerLink]="[sidebarnavSubsubItem.path]"
                                *ngIf="!sidebarnavSubsubItem.extralink;"
                                [routerLinkActive]="sidebarnavSubsubItem.submenu.length > 0 ? '' : 'selected active'"
                            >
                                <i [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu" style="color: #abafb9;">{{sidebarnavSubsubItem.title.toUpperCase()}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul> -->
        </li>
    </ul>
</nav>