import { Component, OnInit } from '@angular/core';
import { SEOServiceService } from '../../../services/SEOService/seoservice.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private SEOServiceService : SEOServiceService) {
      SEOServiceService.updateMeta(()=>{});
  }

  ngOnInit(): void {
  }
}
