import { Device } from './devices.class';
import { Organization } from './organization.class';

/* user types
    1 = Root admin
    2 = Organization Admin
    3 = Warehouse user 
    4 = Conta
    5 = Driver
    6 = Technician
    7 = Final User
*/

export class User {
    idUser: number;
    idRole: number;
    idOrganization: number;
    idTown: number;
    email: string;
    firstName: string;
    lastName: string;
    mothersLastName: string;
    phone: string;
    idConektaAccount?: string | null | undefined;
    lastLoginDate: Date;
    active: boolean;
    deleted: boolean;
    createdAt: Date;
    updatedAt: Date;

    town: TownUserTown;
    role: Role;

    devices?: any;
    organization ?: Organization;
    billingInformation?: BillingInformation | null | undefined ;

    constructor() {
        this.idUser = -1;
        this.idOrganization = -1;
        this.idRole = -1;
        this.idTown = -1;
        this.email = "";
        this.firstName = "";
        this.lastName = "";
        this.mothersLastName = "";
        this.phone = "";
        this.lastLoginDate = new Date();
        this.active = true;
        this.deleted = false;
        this.createdAt = new Date();
        this.updatedAt = new Date();

        this.town = new TownUserTown();
        this.role = new Role();
    }
}
export class TownUserTown {
    idTown: number
    idState: number;
    name: string;

    state: StateUserTown;

    constructor() {
        this.idTown = -1;
        this.idState = -1;
        this.name = "";
    }
}
export class StateUserTown {
    idState: number;
    name: string;

    constructor() {
        //this.idTown = -1;
        this.idState = -1;
        this.name = "";
    }
}
export class Role {
    idRole: number;
    name: string;
    description: string;

    constructor() {
        this.idRole = -1;
        this.name = '';
        this.description = '';
    }
}

export class BillingInformation {
    idBillingInformation: number;
    idUser: number;
    businessName: string;
    rfc: string;
    phone: string;
    email: string;
    state: string;
    city: string;
    zipCode: string;
    suburb: string;
    street: string;
    addressNumber: string;
    facturapiClientToken: string;
    createdAt: Date;
    updatedAt: Date;
    
    constructor(){
        this.idBillingInformation = -1;
        this.idUser = -1;
        this.businessName = "";
        this.rfc = "";
        this.phone = "";
        this.email = "";
        this.state = "";
        this.city = "";
        this.zipCode = "";
        this.suburb = "";
        this.street = "";
        this.addressNumber = "";
        this.facturapiClientToken = "";
        this.createdAt = new Date();
        this.updatedAt = new Date();
    }
  }
  
