<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper" data-layout="vertical" [ngClass]="{'show-sidebar': showMobileMenu}" [dir]="options.dir" [attr.data-theme]="options.theme"
    [attr.data-layout]="options.layout"
    [attr.data-header-position]="options.headerpos" [attr.data-boxed-layout]="options.boxed">
    <!-- ============================================================== -->
    <!-- Topbar header - style you can find in topbar.scss -->
    <!-- ============================================================== -->

    <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
        <!-- <nav class="d-flex top-navbar navbar-expand-md navbar-register"
            [ngClass]="(options.navbarbg=='skin6')?'navbar-light':'navbar-dark'">
            <div class="collapse max-navbar" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg">
                
            </div>
        </nav> -->
        <app-navigation-public></app-navigation-public>
    </header>
    <!-- ============================================================== -->
    <!-- Page content  -->
    <!-- ============================================================== -->
    <div class="content pa-t-80">
        <!-- ============================================================== -->
        <!-- Container fluid  -->
        <!-- ============================================================== -->
        <div class="container-fluid no-padding preregister-box">
            <!-- ============================================================== -->
            <!-- Start Page Content -->
            <!-- ============================================================== -->
            <router-outlet></router-outlet>
            <!-- ============================================================== -->
            <!-- End Start Page Content -->
            <!-- ============================================================== -->
        </div>
        <!-- ============================================================== -->
        <!-- End Container fluid  -->
        <!-- ============================================================== -->
        <!-- ============================================================== -->
        <!-- footer -->
        <!-- ============================================================== -->
        <app-footer-public class="stays-bottom"></app-footer-public>
        <!-- ============================================================== -->
        <!-- End footer -->
        <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Page content  -->
    <!-- ============================================================== -->
    <div class="chat-windows"></div>
</div>