import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Layout para crear una capa donde se renderizan dentro otras vistas
import { BlankComponent } from './layouts/blank/blank.component';

import { FullPublicComponent } from './layouts/full-public/full-public.component';
import { FullAdminComponent } from './layouts/full-admin/full-admin.component';
import { FullOrganizationComponent } from './layouts/full-organization/full-organization.component';
import { FullWarehouseComponent } from './layouts/full-warehouse/full-warehouse.component';
import { FullCounterComponent } from './layouts/full-counter/full-counter.component';

import { FullProductionComponent } from './layouts/full-production/full-production.component';

//Public view components
import { HomeComponent } from './views/public/home/home.component';
import { LoginComponent } from './views/public/login/login.component';
import { NotfoundComponent } from './views/public/404/not-found.component';
import { TermsConditionsComponent } from './views/public/terms-conditions/terms-conditions.component';
import { AboutUsComponent } from './views/public/about-us/about-us.component';
import { FaqComponent } from './views/public/faq/faq.component';
import { PrivacyPoliciesComponent } from './views/public/privacy-policies/privacy-policies.component';
import { RecoveryPasswordComponent } from './views/public/recovery-password/recovery-password.component';
/* import { FullOrganizationComponent } from './layouts/full-organization/full-organization.component'; */

export const Approutes: Routes = [
  { path: '', redirectTo: '/'/* '/landing-page' */, pathMatch: 'full' },
  {
    path: '',
    component: FullPublicComponent,
    children: [
      {
        path: '',
        component : HomeComponent,
        data: {
          title : 'GAWI | Medidores de agua y gas',
          description:'Los mejores medidores de gas y agua',
          keywords : 'gawi,imtech,medidodres,agua,gas,consumo de agua, consumo de gas, medidor de agua, medidor de gas',
          ogUrl: 'https://ingmulti.com/#/',
          ogTitle: 'Los mejores medidores de gas y agua.',
          ogDescription : 'Los mejores medidores de gas y agua.',
          ogImage : 'https://ingmulti.com/#/assets/minified/images/meta.png',
          urls: [
            //{ title: 'Home', url: '/dashboard-admin/home' },
            //{ title: 'Inicio' }
          ]
        }
      },
      {
        path:'about-us',
        component: AboutUsComponent,
        data: {
          title : 'IM Tech | ¿Quines somos?',
          titleMeta : '¿Quienes somos?',
          description:'',
          keywords : '', //Separados por coma ,
          ogUrl: 'https://ingmulti.com/#/preregister',
          ogTitle: '¿Quines somos?',
          ogDescription : '',
          ogImage : 'https://ingmulti.com/#/assets/minified/images/meta.png',
          urls: [
            //{ title: 'Home', url: '/dashboard-admin/home' },
            //{ title: 'Inicio' }
          ]
        }
      },
      {
        path: 'terms-and-conditions',
        component: TermsConditionsComponent,
        data: {
          title : 'IM Tech | Términos y condiciones',
          titleMeta : 'Términos y condiciones',
          description:'',
          keywords : '', //Separados por coma ,
          ogUrl: 'https://ingmulti.com/#/preregister',
          ogTitle: 'Términos y condiciones',
          ogDescription : '',
          ogImage : 'https://ingmulti.com/#/assets/minified/images/meta.png',
          urls: [
            //{ title: 'Home', url: '/dashboard-admin/home' },
            //{ title: 'Inicio' }
          ]
        }
      },
      {
        path: 'privacy-policies',
        component: PrivacyPoliciesComponent,
        data: {
          title : 'IM Tech | Política de privacidad',
          titleMeta : 'Política de privacidad',
          description:'',
          keywords : '', //Separados por coma ,
          ogUrl: 'https://ingmulti.com/#/preregister',
          ogTitle: 'Política de privacidad',
          ogDescription : '',
          ogImage : 'https://ingmulti.com/#/assets/minified/images/meta.png',
          urls: [
            //{ title: 'Home', url: '/dashboard-admin/home' },
            //{ title: 'Inicio' }
          ]
        }
      },
      {
        path:'faq',
        component: FaqComponent,
        data: {
          title : 'IM Tech | Preguntas frecuentes',
          titleMeta : 'Preguntas frecuentes',
          description:'',
          keywords : '', //Separados por coma ,
          ogUrl: 'https://ingmulti.com/#/preregister',
          ogTitle: 'Preguntas frecuentes',
          ogDescription : '',
          ogImage : 'https://ingmulti.com/#/assets/minified/images/meta.png',
          urls: [
            //{ title: 'Home', url: '/dashboard-admin/home' },
            //{ title: 'Inicio' }
          ]
        }
      },


      {
        path:'recovery/:token',
        component: RecoveryPasswordComponent,
        data: {
          title : 'IM Tech | Restablecer contraseña',
          titleMeta : 'Restablecer contraseña',
          description:'',
          keywords : '', //Separados por coma ,
          ogUrl: 'https://ingmulti.com/#/preregister',
          ogTitle: 'Restablecer contraseña',
          ogDescription : '',
          ogImage : 'https://ingmulti.com/#/assets/minified/images/meta.png',
          urls: [
            //{ title: 'Home', url: '/dashboard-admin/home' },
            //{ title: 'Inicio' }
          ]
        }
      },



    ]
  },
  {
    path: '',
    //pathMatch: 'full',
    component: BlankComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
        data: {
          title : 'IM Tech | Iniciar Sesión',
          description:'Iniciar sesión de cuenta en ingmulti.com.',
          keywords : 'Iniciar Sesión, ingmulti, ingmulti.com ',
          ogUrl: 'https://ingmulti.com/#/login',
          ogTitle: 'Iniciar Sesión en ingmulti.com',
          ogDescription : 'Iniciar sesión de cuenta en ingmulti.com.',
          ogImage : 'https://ingmulti.com/#/assets/minified/images/meta.png',
          urls: [
            /* { title: 'Home', url: '/dashboard-admin/home' }, */
            /* { title: 'Inicio' } */
          ]
        }
      },
      {
        path: '404',
        component: NotfoundComponent
      },
    ]
  },
  {
    path: '',
    component: FullAdminComponent,
    children: [
      {
        path: 'dashboard-admin',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/admin/dashboard-admin.module').then(m => m.DashboardAdminModule)
      },
    ]
  },
  {
    path: '',
    component: FullOrganizationComponent,
    children: [
      {
        path: 'dashboard-organization',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/organization/dashboard-organization.module').then(m => m.DashboardOrganizationModule)
      },
    ]
  },
  {
    path: '',
    component: FullWarehouseComponent,
    children: [
      {
        path: 'dashboard-warehouse',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/warehouse/dashboard-warehouse.module').then(m => m.DashboardWarehouseModule)
      },
    ]
  },
  {
    path: '',
    component: FullCounterComponent,
    children: [
      {
        path: 'dashboard-counter',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/counter/dashboard-counter.module').then(m => m.DashboardCounterModule)
      },
    ]
  },

  {
    path: '',
    component: FullProductionComponent,
    children: [
      {
        path: 'dashboard-production',
        //Se carga de esta manera porque dentro del modulo del dashboard vienen componentes que 
        //se comparten y pueden usar en las vistas dentro del modulo
        loadChildren: () => import('./views/dashboard/production/dashboard-production.module').then(m => m.DashboardProductionModule)
      },
    ]
  },

  //Redireccionamiento en caso de que no exista la ruta
  {
    path: '**',
    redirectTo: '404'
  }
];
