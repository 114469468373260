import { RouteInfo } from './sidebar-counter.metadata';

export const COUNTERMENU: RouteInfo[] = [
    {
        path: '/dashboard-counter/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-counter/reports',
        title: 'Reportes',
        icon: 'fas fa-chart-bar',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-counter/organization-payments',
        title: 'Pagos a proveedores',
        icon: 'fas fa-file-invoice-dollar',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-counter/invoice',
        title: 'Facturas',
        icon: 'fas fa-file-invoice',
        class: 'has-arrow',
        extralink: false,
        submenu: [{
                path: '/dashboard-counter/clients-invoices',
                title: 'Clientes',
                icon: 'fas fa-user',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },{
                path: '/dashboard-counter/organizations-invoices',
                title: 'Proveedores',
                icon: 'fas fa-city'/* fas fa-smog */,
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
        ]
    },
    {
        path: '/dashboard-counter/account-setting',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: []
    },
    /*     {
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];
