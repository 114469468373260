import { RouteInfo } from './sidebar-organization.metadata';

export const ORGANIZATIONMENUAGUA: RouteInfo[] = [
    {
        path: '/dashboard-organization/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: []
    },
    
    {
        path: '/dashboard-organization/organization-data',
        title: 'Mi Organización',
        icon: 'fas fa-info',//icon-Posterous , fas fa-birthday-cake
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-organization/organization-users',
        title: 'Usuarios',
        icon: 'fas fa-users',
        class: 'has-arrow',
        extralink: false,
        submenu: [{
            path: '/dashboard-organization/organization-clients-data',
            title: 'Clientes',
            icon: 'fas fa-user-alt',//icon-Posterous , fas fa-birthday-cake
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        }
        /*
        ,{
            path: '/dashboard-organization/organization-technician-data',
            title: 'Técnicos',
            icon: 'fas fa-user-cog',//icon-Posterous , fas fa-birthday-cake
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },{
            path: '/dashboard-organization/organization-drivers-data',
            title: 'Repartidores',
            icon: 'fas fa-user-tie',//icon-Posterous , fas fa-birthday-cake
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },*/
        ]
    },
    // {
    //     path: '/dashboard-organization/water-dashboard',
    //     title: 'Dashboard',
    //     icon: 'fas fa-tachometer',
    //     class: '',
    //     extralink:false,
    //     submenu: []
    // },
    {
        path: '/dashboard-organization/water-devices',
        title: 'Dispositivos',
        icon: 'fas fa-microchip',
        class: '',
        extralink: false,
        submenu: []
    },
    /*
    {
        path: '/dashboard-organization/organization-payments',
        title: 'Ventas',
        icon: 'fas fa-shopping-cart',//sl-icon-pin
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-organization/organization-agenda',
        title: 'Servicios',
        icon: 'fas fa-calendar-week',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-organization/organization-reports',
        title: 'Reportes',
        icon: 'fas fa-chart-bar',
        class: '',
        extralink: false,
        submenu: []
    },
    */
    {
        path: '/dashboard-organization/account-setting',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: []
    },
    /*     {
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];

export const ORGANIZATIONMENUGAS: RouteInfo[] = [

    {
        path: '/dashboard-organization/dashboard-gas',
        title: 'Dashboard',
        icon: 'fas fa-microchip',
        class: '',
        extralink: false,
        submenu: []
    },
    
    {
        path: '/dashboard-organization/organization-data',
        title: 'Mi Organización',
        icon: 'fas fa-info',//icon-Posterous , fas fa-birthday-cake
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-organization/organization-users',
        title: 'Usuarios',
        icon: 'fas fa-users',
        class: 'has-arrow',
        extralink: false,
        submenu: [{
            path: '/dashboard-organization/organization-clients-data',
            title: 'Clientes',
            icon: 'fas fa-user-alt',//icon-Posterous , fas fa-birthday-cake
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        }
        /*
        ,{
            path: '/dashboard-organization/organization-technician-data',
            title: 'Técnicos',
            icon: 'fas fa-user-cog',//icon-Posterous , fas fa-birthday-cake
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },{
            path: '/dashboard-organization/organization-drivers-data',
            title: 'Repartidores',
            icon: 'fas fa-user-tie',//icon-Posterous , fas fa-birthday-cake
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },*/
        ]
    },
    // {
    //     path: '/dashboard-organization/water-dashboard',
    //     title: 'Dashboard',
    //     icon: 'fas fa-tachometer',
    //     class: '',
    //     extralink:false,
    //     submenu: []
    // },
    {
        path: '/dashboard-organization/organization-devices',
        title: 'Dispositivos',
        icon: 'fas fa-microchip',
        class: '',
        extralink: false,
        submenu: []
    },
    /*
    {
        path: '/dashboard-organization/organization-payments',
        title: 'Ventas',
        icon: 'fas fa-shopping-cart',//sl-icon-pin
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-organization/organization-agenda',
        title: 'Servicios',
        icon: 'fas fa-calendar-week',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-organization/organization-reports',
        title: 'Reportes',
        icon: 'fas fa-chart-bar',
        class: '',
        extralink: false,
        submenu: []
    },
    */
    {
        path: '/dashboard-organization/account-setting',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: []
    },
    /*     {
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];
