<div class="navbar-register white-bg fixed-shadow">
    <div class="max-navbar">
        <div class="row full-width">
            <div class="col-4 full-centered">
                <a class="btn btn-download max-btn-animate" target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.imtech.gawi.gas&hl=es_MX">
                    Descarga la app
                </a>
            </div>
            <div class="col-4 full-centered">
                <a routerLink="/">
                    <img class="logo-nav-bar" src="/assets/images/logos/logohd.png">
                </a>
            </div>
            <div class="col-2 full-right">
                <a routerLink="/faq" class="pa-r-10">
                    Preguntas
                </a>
            </div>
            <div class="col-2 full-left">
                <a routerLink="/login" class="pa-l-10">
                    Empresas
                </a>
            </div>
        </div>
        <!-- <div class="dropdown right-side-mobile" ngbDropdown>
            <span>
                <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic"
                    href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="flag-icon flag-icon-mx" style="padding: 16px;margin: 15px;"></i>
                    <img src="assets/images/users/no-user-image.jpg" alt="user" class="rounded-circle" width="31">
                </a>
                <div class="dropdown-menu-right user-dd normal-shadow" ngbDropdownMenu style="box-shadow: 4px 5px 7px -2px rgb(0 0 0 / 50%);">
                    <span *ngIf="dataSessionService.user.idUser != -1">
                        <span class="with-arrow">
                            <span class="bg-account"></span>
                        </span>
                        <div class="d-flex no-block align-items-center p-15 bg-account text-white m-b-10">
                            <div class="">
                                <img src="assets/images/users/no-user-image.jpg" alt="user" class="img-circle"
                                    width="60">
                            </div>
                            <div class="m-l-10">
                                <h4 class="m-b-0">{{dataSessionService.user.lastName}}</h4>
                                <p class=" m-b-0">{{dataSessionService.user.email}}</p>
                            </div>
                        </div>
                        <div class="dropdown-divider"></div>
                       
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="javascript:void(0)" (click)="logOut()">
                            <i class="fa fa-power-off m-r-5 m-l-5"></i> Cerrar sesión</a>
                        <div class="dropdown-divider"></div>
                    </span>

                    <span *ngIf="dataSessionService.user.idUser == -1" class="">
                        <span class="with-arrow">
                            <span class="bg-account" style="background-color: #ffffff !important;
                            border: 1px solid #e8e8e8 !important;"></span>
                        </span>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item">
                            <p class=" dropdown-item pa-r-30 pa-t-5 normal-txt bg-color">
                                Llámanos al 614 397 4540
                            </p>
                        </a>
                        <div class="dropdown-divider"></div>
                        <span class="w-100 full-centered">
                            <a routerLink="/login" class="dropdown-item sign-in-btn bg-color" style="width: fit-content;">
                                <i class="far fa-user"></i>
                                Iniciar Sesión
                            </a>
                        </span>

                        <div class="dropdown-divider"></div>
                    </span>

                </div>
            </span>
        </div> -->
    </div>
</div>