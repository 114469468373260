import { RouteInfo } from './sidebar-warehouse.metadata';

export const WAREHOUSEMENU: RouteInfo[] = [
    {
        path: '/dashboard-warehouse/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '',
        title: 'Dispositivos',
        icon: 'fas fa-microchip',
        class: 'has-arrow',
        extralink: false,
        submenu: [{
            path: '/dashboard-warehouse/water-devices',
            title: 'Agua',
            icon: 'fas fa-tint',
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },{
            path: '/dashboard-warehouse/gas-devices',
            title: 'Gas',
            icon: 'fas fa-burn',
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },{
            path: '/dashboard-warehouse/logger-devices',
            title: 'Data Logger',
            icon: 'fas fa-tablet-rugged',
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },{
            path: '/dashboard-warehouse/natural-gas-devices',
            title: 'Gas Natural',
            icon: 'fas fa-weight',
            class: 'padding-submenu',
            extralink: false,
            submenu: []
        },]
    },{
        path: '/dashboard-warehouse/qr-generator',
        title: 'Códigos QR',
        icon: 'fas fa-qrcode',
        class: '',
        extralink: false,
        submenu: []
    },{
        path: '/dashboard-warehouse/calibrator',
        title: 'Calibrador',
        icon: 'fas fa-thermometer-three-quarters',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-warehouse/account-setting',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: []
    },
    /*     {
        path: '/apps/email',
        title: 'Email',
        icon: 'icon-Mailbox-Empty',
        class: '',
        extralink: false,
        submenu: []
    }, */
];
