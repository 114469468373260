<section class="max-width-page p-t-50 p-b-50">
    <h3 class="blu-20 fw-400 pa-b-20">Aviso de privacidad</h3>
    <p class="black-16 justify-text pa-b-20">
        De conformidad con lo dispuesto en la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares y a los requisitos que dicha legislación establece, IMTECH, da a
        conocer el presente documento:
    </p>
    <p class="black-16 justify-text pa-b-20">
        Para efectos del presente Aviso de Privacidad, se entenderá por:
    </p>
    <p class="black-16 justify-text pa-b-20">
        Aviso de Privacidad: Se refiere al presente documento, el cual es puesto a disposición del
        TITULAR, previo al tratamiento de sus datos personales, de conformidad con el artículo 15 de la
        Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Datos personales: Cualquier información concerniente a una persona física identificada o
        identificable.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Datos personales sensibles: Aquellos datos personales que afecten a la esfera más íntima del
        TITULAR, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo
        grave para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos
        como origen racial o étnico, estado de salud presente y futuro, información genética, creencias
        religiosas, filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Derechos ARCO: Se refiere a los derechos de acceso, cancelación, rectificación y oposición con
        los que cuenta el TITULAR en relación a sus datos personales.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Encargado: La persona física o moral que sola o conjuntamente con otras trate datos personales
        por cuenta del responsable.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Ley: Ley Federal de Protección de Datos Personales en Posesión de los Particulares y/o su
        Reglamento.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Responsable: IMTECH, en su carácter de persona moral privada que decide sobre el tratamiento
        de datos personales.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Titular: La persona física a quien corresponden los datos personales.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Tratamiento: Obtención, uso, divulgación o almacenamiento de datos personales por cualquier
        medio. El uso abarca cualquier acción de acceso, manejo, aprovechamiento, transferencia o
        disposición de datos personales.
    </p>
    <p class="black-16 justify-text pa-b-20">
        El presente Aviso de Privacidad se pone a disposición del TITULAR, en cumplimiento a lo
        dispuesto por la Ley Federal de Protección de Datos Personales en posesión de los Particulares,
        así como demás disposiciones legales aplicables.
    </p>
    <p class="black-16 justify-text pa-b-20">
        IMTECH DESARROLLOS es una SAPI de CV legalmente constituida de conformidad con las
        leyes de la República Mexicana, con domicilio fiscal en Calle Pedro Vargas #7515 Col.
        Churubusco, C.P. 31120, Chihuahua, Chihuahua siendo RESPONSABLE del tratamiento de los
        datos personales del TITULAR que otorga su consentimiento al presente aviso. IMTECH con el
        compromiso de observar los principios de licitud, consentimiento, información, calidad, finalidad,
        lealtad, proporcionalidad y responsabilidad en el tratamiento de datos personales y los derechos
        de privacidad y autodeterminación informativa, hace constar en el presente aviso de privacidad
        lo siguiente:
    </p>
    <p class="black-16 justify-text pa-b-20">
        1) La Empresa tratará su información y datos personales como confidenciales y mantendrá
        medidas preventivas dirigidas a protegerla contra pérdida, mal uso, acceso no autorizado,
        alteración o destrucción, no la divulgará para otro propósito que no sea el establecido en el
        presente Aviso de Privacidad. Su información será tratada como confidencial y será administrada
        durante el tiempo necesario para lograr los fines mencionados en el presente Aviso Privacidad,
        el cual podrá actualizarse para reflejar los cambios en nuestras prácticas para recabar
        información, para la utilización de la información, y en materia de seguridad.
    </p>
    <p class="black-16 justify-text pa-b-20">
        2) Los datos personales que tratará IMTECH, incluyendo la obtención, uso, divulgación o
        almacenamiento de tales datos por cualquier medio de acceso, manejo, aprovechamiento,
        transferencia o disposición, son aquellos que usted en su calidad de TITULAR, ha proporcionado
        o proporcione a IMTECH y aquellos a los que IMTECH tiene acceso legítimamente por haber
        sido proporcionados para los fines que más adelante se señalan.
    </p>
    <p class="black-16 justify-text pa-b-20">
        A continuación, se enlistan algunos de los datos personales a que se refiere este apartado:
        <li>
            Nombre
        </li>
        <li>
            Compañía
        </li>
        <li>
            Dirección
        </li>
        <li>
            Información de contacto laboral, incluyendo teléfono, correo electrónico y otros.
        </li>
    </p>
    <p class="black-16 justify-text pa-b-20">
        La lista anterior debe entenderse como enunciativa, más no limitativa de aquellos datos de
        carácter personal que serán tratados por IMTECH, en el entendido que se trata de datos
        personales de la misma naturaleza.
    </p>
    <p class="black-16 justify-text pa-b-20">
        3) IMTECH hace de su conocimiento que los datos personales del titular serán tratados por
        IMTECH y/o las empresas afiliadas o subsidiarias de la misma, encargadas que actúen en
        nombre de IMTECH y terceros, distintos a IMTECH o al titular de los datos, quienes deberán
        cumplir con el presente Aviso de Privacidad.
    </p>
    <p class="black-16 justify-text pa-b-20">
        4) Las finalidades del tratamiento de los datos personales del TITULAR por parte de IMTECH,
        son las que se enuncian a continuación, así como todas aquellas que resulten análogas:
        <li>
            Envío de publicidad informativa relativa a temas de servicios industriales.
        </li>
        <li>
            Fines informativos, administrativos y/o comerciales relacionados con IMTECH.
        </li>
    </p>
    <p class="black-16 justify-text pa-b-20">
        5) Al acceder al presente sitio de internet o bien al instalar la presente aplicación y aceptar el
        presente Aviso de Privacidad, usted en su carácter de TITULAR otorga expresamente a IMTECH
        su consentimiento para la transferencia nacional e internacional de sus datos personales,
        siempre que el receptor de los datos asuma las mismas obligaciones asumidas por IMTECH.
        Asimismo, IMTECH se compromete a transferir solo aquella información que sea necesaria para
        la misma finalidad con la que se emite el presente aviso.
    </p>
    <p class="black-16 justify-text pa-b-20">
        6) También podemos compartir su información:
    </p>
    <p class="black-16 justify-text pa-b-20">
        I. Cuando alguna autoridad gubernamental o diverso funcionario gubernamental responsable de
        hacer cumplir la ley solicite o requiera razonablemente dicha información;
    </p>
    <p class="black-16 justify-text pa-b-20">
        II. Cuando lo exija la ley o en respuesta a algún proceso legal;
    </p>
    <p class="black-16 justify-text pa-b-20">
        III. Cuando sea razonablemente necesario para llevar a cabo una investigación de carácter legal.
    </p>
    <p class="black-16 justify-text pa-b-20">
        7) IMTECH establecerá y mantendrá medidas de seguridad, administrativas, técnicas y físicas
        que permitan proteger los datos personales contra daño, pérdida, alteración, destrucción o el
        uso, acceso o tratamiento no autorizado. Estas medidas no serán menores a aquellas que
        mantenga IMTECH para el manejo de su propia información.
    </p>
    <p class="black-16 justify-text pa-b-20">
        8) Derechos: Conforme a la Ley de Protección de Datos Personales Usted tiene el derecho de
        ejercer en cualquier momento sus derechos de acceso, rectificación, cancelación y oposición (los
        "Derechos ARCO") de su información, mediante una solicitud por escrito dirigida a
        contacto@gawi.mx, quien podrá solicitarle para su protección y beneficio, documentación que
        acredite correcciones a los datos en caso de que solicite rectificación de estos. Usted puede
        solicitar que se cancelen sus datos personales que IMTECH conserva. Asimismo, Usted podrá
        limitar el uso y distribución de sus datos personales a través de una solicitud por escrito a La
        Empresa. No obstante, lo anterior, es posible que La Empresa se vea obligada conforme a la
        legislación aplicable a conservar algunos o todos sus datos personales. Es importante que los
        cambios que pretenda hacer a sus datos personales sean correctos y no se eliminarán aquellos
        datos que por obligación o disposición legal deba de conservar la empresa.
    </p>
    <p class="black-16 justify-text pa-b-20">
        La solicitud deberá ser presentada por el TITULAR o su representante legal, a la dirección antes
        mencionada, dicha solicitud deberá contener:
    </p>
    <p class="black-16 justify-text pa-b-20">
        i) El nombre completo del TITULAR y domicilio u otro medio para comunicarle la respuesta,
        incluyendo dirección de correo electrónico.
    </p>
    <p class="black-16 justify-text pa-b-20">
        ii) Los documentos que acrediten su identidad o la del representante legal,
    </p>
    <p class="black-16 justify-text pa-b-20">
        iii) La descripción clara y precisa de los datos personales respecto de lo que busca ejercer sus
        derechos,
    </p>
    <p class="black-16 justify-text pa-b-20">
        iv) Cualquier otro elemento que facilite la localización de los datos personales del TITULAR.
    </p>
    <p class="black-16 justify-text pa-b-20">
        IMTECH comunicará al titular en máximo siete días naturales, contados a partir de haber recibido
        la solicitud de acceso, rectificación, cancelación u oposición, la determinación adoptada, a efecto
        de que se haga efectiva dentro de los siete días naturales siguientes. Estos plazos podrán ser
        ampliados por un periodo igual cuando a discreción de IMTECH, las circunstancias del caso lo
        justifiquen.
    </p>
    <p class="black-16 justify-text pa-b-20">
        El derecho de acceso procede cuando el titular desee conocer cuáles de sus datos personales
        obran en poder de IMTECH y el aviso de privacidad que le es aplicable.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Se dará cumplimiento a una solicitud de acceso, poniendo a disposición del TITULAR o su
        representante, previo acreditamiento de su identidad, los documentos donde obren los datos
        personales requeridos, por el medio que determine IMTECH. La entrega de los datos será
        gratuita siempre y cuando no se repita la solicitud de acceso en un periodo menor a 12 meses.
        El TITULAR únicamente cubrirá los costos de reproducción en copias u otros formatos.
    </p>
    <p class="black-16 justify-text pa-b-20">
        El TITULAR podrá rectificar sus datos personales cuando estos sean inexactos o incompletos,
        indicando en la solicitud de rectificación las modificaciones que deban realizarse y aportando a
        IMTECH la documentación que sustente su petición. En caso de ser procedente la solicitud del
        TITULAR, IMTECH deberá informar de los cambios de que se trate a los encargados del
        tratamiento y a terceros, en caso de que haya habido transferencias de datos en los términos del
        presente Aviso de Privacidad.
    </p>
    <p class="black-16 justify-text pa-b-20">
        El derecho de cancelación consiste en la supresión del dato y puede ir precedido por un periodo
        de bloqueo en el que los datos no podrán ser objeto de tratamiento. No procederá la cancelación
        de los datos personales en los casos previstos por la Ley.
    </p>
    <p class="black-16 justify-text pa-b-20">
        El TITULAR tendrá derecho en todo momento y por causa legítima a oponerse al tratamiento de
        sus datos. De resultar procedente la solicitud, IMTECH no podrá tratar los datos del TITULAR.
    </p>
    <p class="black-16 justify-text pa-b-20">
        IMTECH podrá negar el acceso a los datos personales, o realizar la rectificación o cancelación o
        conceder la oposición cuando el solicitante no sea el TITULAR o el representante legal no esté
        debidamente acreditado para ello, cuando en su base de datos no se encuentren los datos
        personales del titular, cuando se lesionen derechos de un tercero, cuando exista un impedimento
        legal o una resolución de una autoridad competente que restrinja el acceso a los datos personales
        o no permita su rectificación, cancelación u oposición y cuando la rectificación, cancelación u
        oposición haya sido previamente realizada.
    </p>
    <p class="black-16 justify-text pa-b-20">
        9) En caso de que se efectúen cambios al presente Aviso de Privacidad, IMTECH los hará del
        conocimiento del titular mediante notificación escrita que será publicada en la página de Internet
        gawi.mx y enviada a su vez a la dirección de correo electrónico por la cual el TITULAR le haya
        notificado a IMTECH previamente. Si el TITULAR está de acuerdo con las modificaciones hechas
        al Aviso de Privacidad deberá hacer clic en la casilla que indica aceptación, si no está de acuerdo
        deberá hacer clic en la casilla que indica inconformidad, las implicaciones de esto se detallarán
        en el propio correo, si el TITULAR no responde dentro de los siguientes 5 días naturales se le
        tomará por notificado.
    </p>
    <p class="black-16 justify-text pa-b-20">
        10) El consentimiento para el tratamiento de datos personales podrá ser revocado mediante
        aviso por escrito, que el TITULAR proporcione por escrito, dirigido al correo electrónico señalado
        en el presente Aviso de Privacidad, en el cual incluya las razones por las que revoca el
        consentimiento.
    </p>
    <p class="black-16 justify-text pa-b-20">
        11) IMTECH dará trámite a las solicitudes de acceso, rectificación, cancelación y oposición, labor
        que estará a su cargo, cuyo domicilio físico se encuentra ubicado en la dirección señalada al
        inicio del presente aviso y cuyo correo electrónico para cualquier duda o comentario respecto al
        presente Aviso de Privacidad se ha señalado anteriormente.
    </p>
    <p class="black-16 justify-text pa-b-20">
        Al proporcionarnos sus datos Usted reconoce que entiende y acepta la recopilación y transmisión
        de su información y datos personales por parte de IMTECH según se señala en el presente Aviso
        de Privacidad.
    </p>
</section>