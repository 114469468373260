<div style="display: flex; align-items: center; justify-content: center">
  <div class="card-body max-w-500" style="padding: 40px">
    <div
      class="logo"
      style="display: flex; align-items: center; justify-content: center"
    >
      <a class="db" routerLink="/">
        <img
          src="/assets/images/logos/logo_im_300x300.png"
          alt="logo"
          style="height: 100px"
        />
      </a>
    </div>

    <h1 class="font-medium m-b-20" style="text-align: center">
      Restablecer contraseña
    </h1>
    <form [formGroup]="recoveryForm">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"
            ><i class="ti-email"></i
          ></span>
        </div>

        <input
          type="email"
          matInput
          pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,3}"
          class="form-control"
          id="email"
          name="email"
          placeholder="Correo electrónico"
          required
          formControlName="email"
        />

        <div
          *ngIf="email.invalid && email.touched"
          class="validation-label alert alert-danger no-margin"
        >
          <div *ngIf="email.errors.required">Correo electrónico requerido.</div>
          <div *ngIf="email.invalid">Correo electrónico invalido.</div>
        </div>
      </div>

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon2"
            ><i class="ti-pencil"></i
          ></span>
        </div>
        <input
          type="password"
          class="form-control"
          id="password"
          name="password"
          placeholder="Nueva contraseña"
          required
          formControlName="password"
        />

        <div
          *ngIf="password.invalid && password.touched"
          class="validation-label alert alert-danger no-margin"
        >
          <div *ngIf="password.errors.required">Contraseña requerida.</div>
          <div *ngIf="password.errors.minlength">
            La contraseña debe contener por lo menos 8 caracteres.
          </div>
        </div>
      </div>

      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon2"
            ><i class="ti-pencil"></i
          ></span>
        </div>
        <input
          type="password"
          class="form-control"
          id="retypedPassword"
          name="retypedPassword"
          placeholder="Confirmar nueva contraseña"
          required
          formControlName="retypedPassword"
        />

        <div
          *ngIf="password.invalid && password.touched"
          class="validation-label alert alert-danger no-margin"
        >
          <div *ngIf="password.errors.required">Contraseña requerida.</div>
          <div *ngIf="password.errors.minlength">
            La contraseña debe contener por lo menos 8 caracteres.
          </div>
        </div>
      </div>

      <div class="row m-t-20">
        <div class="col-12">
          <button
            [disabled]="checkCredentials() == false"
            (click)="recoverPassword()"
            class="btn btn-block btn-lg btn-success color-white max-btn-animate normal-shadow"
            type="submit"
            name="action"
          >
            Restablecer
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
