<section class="max-width-page p-t-120 p-b-50">
    <h3 class="blu-20 fw-400">Terminos y Condiciones</h3>
    
    
    <p class="black-16 justify-text pa-b-20">
        Al hacer clic en la casilla que indica aceptación el Cliente asume los términos de este Acuerdo.
        Si la persona que acepta este Acuerdo lo acepta en nombre de una Compañía u otra entidad
        legal, dicha persona declara que tiene la autoridad para obligar a dicha entidad y sus afiliados a
        estos términos y condiciones, en cuyo caso, el término "Cliente" se referirá a dicha entidad y sus
        afiliados. Sin embargo, si la persona carece de dicha autoridad o no está de acuerdo con estos
        términos y condiciones, dicha persona no debe aceptar este Acuerdo y no puede usar los
        Servicios.
    </p>

    <h4 class="blu-16 pa-b-20">
        1. Equipo Técnico IMTECH.
    </h4>
    <p class="black-16 justify-text pa-b-20">
        1.1 IMTECH acepta proporcionar y/o entregar al Cliente los servicios ("Servicios") y los productos
        y sensores de Hardware ("Hardware", y junto con los “Servicios”, colectivamente, el "Equipo"),
        establecidos en una o más órdenes de trabajo que se emitirá a continuación (cada uno, una
        "Orden"). A menos que se indique lo contrario en la Orden aplicable, el Equipo se compra por
        suscripción por plazo establecido en la Orden, este plazo será únicamente anual hasta que se
        informe de cambio alguno. El cliente acepta que sus compras no están supeditadas a la entrega
        de funciones o innovaciones futuras, ni dependen de comentarios públicos orales o escritos
        realizados por IMTECH con respecto a funciones o innovaciones futuras.
    </p>
    <h4 class="blu-16 pa-b-20">
        2. Honorarios, Gastos y Método de Pago.
    </h4>
    <p class="black-16 justify-text pa-b-20">
        2.1 Método de Pago. IMTECH estará facultado para:
    </p>
    <p class="black-16 justify-text pa-b-20">
        2.1 Pago y Facturación. En contraprestación por el Equipo y los derechos otorgados al Cliente
        en virtud de este Acuerdo, el Cliente pagará las tarifas y los gastos establecidos en la Orden
        correspondiente. A menos que se indique lo contrario en la Orden, el primer pago habrá de
        realizarse en el momento de la ejecución de este Acuerdo. En el caso de que el Cliente notifique
        de una disputa con respecto a un cobro, el Cliente acepta, no obstante, pagar la parte no
        disputada del cobro de manera oportuna. Los datos de facturación del Cliente podrían ser
        compartidos con terceros, con estricto apego a la ley y con la única finalidad de facilitar el proceso
        de facturación al cliente.
    </p>
    <p class="black-16 justify-text pa-b-20">
        2.2 Impuestos. El Cliente será responsable de todos los impuestos ajenos al IVA, el cual IMTECH
        está autorizado de cobrar en la tarifa.
    </p>
    <p class="black-16 justify-text pa-b-20">
        2.3 Pagos atrasados. Excepto por los montos en disputa que el Cliente haya disputado con éxito,
        todos los pagos atrasados devengarán intereses de acuerdo con el interés legal del 9% anual,
        calculada diariamente y capitalizada mensualmente, lo que sea menor. El cliente también deberá
        reembolsar a IMTECH por todos los costos incurridos en el cobro de los pagos atrasados,
        incluidos los honorarios de los abogados. Además de todos los demás recursos disponibles en
        virtud de este Acuerdo o por ley (a los que IMTECH no renuncia mediante el ejercicio de cualquier
        derecho en virtud del presente), IMTECH tendrá derecho a suspender la provisión de cualquier
        Equipo si el Cliente no paga los montos no disputados a su vencimiento en virtud del presente y
        dicho incumplimiento continúa durante los 30 días posteriores a la notificación por escrito del
        mismo.
    </p>
    <h4 class="blu-16 pa-b-20">
        3. Cooperación; Coordinación.
    </h4>
    <p class="black-16 justify-text pa-b-20">
        3.1 En general. El Cliente hará que sus empleados, agentes, contratistas y otros terceros
        contratados por el Cliente cooperen y coordinen con IMTECH en la provisión del Equipo y brinden
        acceso razonable a las Instalaciones, empleados y herramientas según sea necesario para
        permitir que IMTECH manipule el Equipo.
    </p>
    <p class="black-16 justify-text pa-b-20">
        3.2 Inspección y Notificación. El Cliente deberá informar al equipo de instalación de IMTECH
        sobre cualquier problema, defecto o condición que pueda afectar la capacidad de IMTECH para
        instalar el Hardware o que pueda afectar el área de instalación (p. ej., fugas actuales,
        preexistentes o potenciales, moho, grietas, sarro, etc.). Si el Cliente cree que el Hardware que
        recibe de IMTECH es defectuoso, deberá notificar a IMTECH dentro de los 30 días siguientes a
        la recepción del Hardware y poner el Hardware a disposición de IMTECH de informarle requerido.
        Si IMTECH confirma que alguna parte del Hardware está defectuosa, IMTECH a su elección,
        reparará o reemplazará el Hardware defectuoso, a cargo de IMTECH, o reembolsará al Cliente
        el costo del Hardware defectuoso. IMTECH tendrá derecho a exigir al Cliente que devuelva
        cualquier Hardware defectuoso. El hecho de que el Cliente no notifique cualquier reclamo dentro
        de los 30 días posteriores a la recepción del Hardware constituirá una renuncia incondicional por
        parte del Cliente a sus reclamos relacionados a dicho Hardware. Cuando el Equipo haya sido
        instalado en el sitio por representantes de IMTECH, el Cliente acepta que no moverá, ajustará o
        modificará el Equipo una vez instalado sin el consentimiento previo por escrito de IMTECH y no
        autorizará ni permitirá que su personal los mueva. Cuando el Cliente instale el Equipo, acepta
        que dicha instalación debe cumplir con las instrucciones y especificaciones puestas a disposición
        por IMTECH. (Hacer que la palabra IMTECH sea un hipervínculo a las instrucciones.) IMTECH
        se esforzará por notificar al Cliente sobre cualquier condición preexistente que pueda afectar el
        rendimiento o la condición del Hardware o las Instalaciones del Cliente; sin embargo, IMTECH
        no es responsable de los daños causados por las condiciones que existían antes de la instalación
        del Hardware y no podrá notificar al Cliente sobre ningún problema que no sea visible,
        independientemente de las circunstancias de la instalación.
    </p>
    <h4 class="blu-16 pa-b-20">
        4. Propiedad Intelectual.
    </h4>
    <p class="black-16 justify-text pa-b-20">
        4.1 Propiedad. Todos los derechos, en y para toda la Propiedad Intelectual, documentos,
        productos de trabajo y otros materiales que se entregan al Cliente en virtud de este Acuerdo o
        preparados por o en nombre de IMTECH, ya sea en el curso de la prestación de los Servicios y
        el suministro del Hardware o de otro modo, incluidos cualquier artículo identificado como tal en
        la Orden (colectivamente, el "equipo de trabajo") será propiedad exclusiva de IMTECH .
        “Propiedad intelectual” significa cualquiera o todos los siguientes y todos los derechos, títulos e
        intereses sobre los mismos: (a) cualquier programa de computadora, sistema operativo, sistema
        de aplicaciones, código de objeto de firmware, código fuente o software de cualquier naturaleza;
        (b) nombres, nombres corporativos, nombres de dominio, nombres ficticios, marcas registradas,
        solicitudes de marcas registradas, marcas de servicio, solicitudes de marcas de servicio,
        nombres comerciales, nombres de marcas, nombres de productos y eslóganes, y buena voluntad
        asociada con cualquiera de los anteriores; (c) invenciones (patentables o no), divulgaciones de
        invenciones, mejoras, secretos comerciales, información confidencial o patentada, know-how,
        derechos de productos, tecnología, datos técnicos, modelos, algoritmos, herramientas,
        metodologías de procesos comerciales y toda la documentación relacionada con cualquiera de
        los anteriores; (d) patentes y solicitudes de patentes, y todas las reemisiones, divisiones,
        renovaciones, extensiones, provisionales, continuaciones y continuaciones parciales de las
        mismas; (e) derechos de autor, registros de derechos de autor y solicitudes de los mismos, y
        todos los demás derechos correspondientes a los mismos; (f) sitios web y todos los diseños
        relacionados con los mismos; (g) bases de datos y recopilaciones de datos (incluidos los datos
        normativos y las bases de datos normativas) y todos los derechos sobre los mismos; (h) los
        derechos morales y patrimoniales de los autores e inventores, cualquiera que sea su
        denominación; (i) derechos similares o equivalentes a cualquiera de los anteriores y otros activos
        intangibles o de cualquier naturaleza; (j) fondo de comercio y reclamaciones de infracción y
        apropiación indebida contra terceros; k) sistemas internos, incluidos los sistemas de Hardware
        informático y los sistemas integrados; y (l) todos los registros, renovaciones, extensiones,
        combinaciones, divisiones o reemisiones de cualquiera de los anteriores.
    </p>
    <p class="black-16 justify-text pa-b-20">
        4.3 Datos. Cualquier y toda la información obtenida en relación con el Equipo (colectivamente,
        los "Datos"), junto con todos y cada uno de los datos que se derivan del procesamiento de los
        Datos, incluidos los datos de uso, salida, copias, reproducciones, mejoras, modificaciones,
        adaptaciones, las traducciones, informes, análisis, interpretaciones, reorganizaciones,
        agregaciones, combinaciones, iteraciones, modelos, derivados, extensiones o compilaciones de
        los Datos creados por IMTECH (los "Datos derivados") serán propiedad exclusiva de IMTECH .
        IMTECH otorga al Cliente una licencia de los Datos sin cargo adicional y de forma no exclusiva,
        mundial, transferible, sublicenciable, totalmente pagada, libre de regalías y perpetua.
    </p>
    <p class="black-16 justify-text pa-b-20">
        4.4 Información confidencial. Cada Parte acuerda que no usará ni divulgará a ningún tercero,
        excepto con el fin de ejecutar este Acuerdo, cualquier información comercial o técnica (incluida
        la propiedad intelectual) de la otra Parte que se designe, o en el ejercicio de un juicio razonable,
        debe ser reconocido por dicha Parte receptora como confidencial ("Información confidencial"). La
        obligación de confidencialidad no se aplicará a la información que: (a) sea o pase a ser de
        dominio público sin culpa de la Parte receptora; (b) sea proporcionada por la Parte reveladora a
        otros sin restricciones de uso y divulgación; (c) sea conocido o esté disponible para la Parte
        receptora sin restricción de una fuente distinta a la Parte reveladora sin incumplimiento de ningún
        acuerdo con la Parte reveladora; (d) se divulgue con la aprobación previa por escrito de la Parte
        divulgadora; (e) sea desarrollado de forma independiente por la Parte receptora sin el uso de
        ninguna Información Confidencial y sin incumplimiento de este Acuerdo; o (f) sea previamente
        conocido por la Parte receptora de manera no confidencial. La Parte receptora puede divulgar
        Información confidencial en la medida en que lo exija una orden judicial, una ley, una agencia
        gubernamental u otro proceso legal, en cuyo caso, la Parte receptora deberá dar a la Parte
        reveladora la notificación que sea razonablemente práctica y permisible para que la Parte
        reveladora puede buscar una orden de protección u otra protección confidencial como la Parte
        reveladora, a su exclusivo criterio, puede elegir y la Parte receptora cooperará razonablemente
        con la Parte reveladora en los esfuerzos de la Parte reveladora para obtener dicha orden o
        protección. Las Partes acuerdan que los términos, pero no la existencia de este Acuerdo o la
        relación de las Partes, se considerarán Información Confidencial. Las obligaciones de esta
        Sección sobrevivirán durante 5 años después de la terminación de este Acuerdo.
    </p>
    <h4 class="blu-16 pa-b-20">
        5. Plazo, Terminación y Supervivencia.
    </h4>
    <p class="black-16 justify-text pa-b-20">
        5.1 Plazo del Acuerdo. A menos que se habilite que el Cliente seleccione otro plazo en el pedido,
        el plazo inicial de este Acuerdo será de un año a partir de la contratación y se renovará
        automáticamente por períodos sucesivos de un año a menos que alguna de las Partes rescinda
        el contrato.
    </p>
    <p class="black-16 justify-text pa-b-20">
        5.2 Rescisión por incumplimiento de las Partes. Cualquiera de las Partes puede rescindir este
        Acuerdo o cualquiera de los Servicios proporcionados en una Orden, con efecto mediante
        notificación por a la otra Parte (la "Parte incumplidora"), si la Parte incumplidora incumple este
        Acuerdo, y dicho incumplimiento no puede subsanarse, o con respecto a un incumplimiento
        capaz de subsanar la Parte incumplidora no subsana dicho incumplimiento dentro de 30 días
        posteriores a la recepción de la notificación que describe dicho incumplimiento con detalles
        razonables, se le tomará como motivo de rescisión. El Cliente puede rescindir este Acuerdo en
        cualquier momento tras la Fecha de entrada en vigencia del Contrato de Préstamo de Servicios,
        siguiendo el procedimiento señalado por IMTECH (añadir un hipervínculo a dicho proceso) sin
        embargo, el Cliente deberá pagar a IMTECH una multa por terminación equivalente al valor del
        50% de los resto días que se tenían previstos en el contrato.
    </p>
    <p class="black-16 justify-text pa-b-20">
        5.3 Rescisión por voluntad del Cliente. El Cliente puede rescindir este Acuerdo en cualquier
        momento tras la Fecha de entrada en vigencia del Contrato de Préstamo de Servicios, siguiendo
        el procedimiento señalado por IMTECH (añadir un hipervínculo a dicho proceso) sin embargo,
        en caso de no deberse a un incumplimiento contractual de IMTECH, NO HABRAN
        DEVOLUCIONES.
    </p>
    <p class="black-16 justify-text pa-b-20">
        5.4 Efecto de la Terminación. A la terminación de este Acuerdo, a menos que las Partes acuerden
        lo contrario (a) todos y cada uno de los montos adeudados a IMTECH permanecerán vencidos y
        pagaderos de acuerdo con los términos de pago aplicable y (b) todas las licencias otorgadas al
        Cliente por IMTECH de conformidad con este Acuerdo terminarán de inmediato y sin previo
        aviso.. Los efectos de terminación descritos anteriormente no se interpretarán como una
        limitación de los derechos o recursos de las Partes a los que tiene derecho según la ley o la
        equidad y no eximirán al Cliente de las obligaciones del Cliente con respecto a la confidencialidad
        del Software con licencia.
    </p>
    <p class="black-16 justify-text pa-b-20">
        5.5 Supervivencia. Todas las disposiciones de este Acuerdo que, por su naturaleza, están
        claramente destinadas a sobrevivir a la terminación de este Acuerdo sobrevivirán a la terminación
        o vencimiento de este Acuerdo o cualquier Orden.
    </p>
    <h4 class="blu-16 pa-b-20">
        6. Representaciones y Garantías.
    </h4>
    <p class="black-16 justify-text pa-b-20">
        6.1 Garantías de IMTECH. IMTECH representa y garantiza al Cliente lo siguiente a partir de la
        Fecha de entrada en vigencia y en adelante, con la intención de que dichas representaciones y
        garantías sobrevivan a la Fecha de entrada en vigencia: (a) IMTECH está debidamente
        organizado, tiene una existencia válida y está al corriente de conformidad con las leyes del
        estado. de la formación de IMTECH y posee el poder de celebrar este Acuerdo y cumplir con los
        términos y condiciones de este Acuerdo sin violar ningún otro acuerdo del que sea parte en
        cualquier aspecto material, (b) la ejecución, entrega y ejecución de este Acuerdo, y las
        transacciones contempladas por este Acuerdo, por parte de IMTECH han sido debida y
        válidamente autorizadas y aprobadas por todas las acciones requeridas por parte de IMTECH ,
        (c) este Acuerdo es una obligación legal, válida y vinculante de IMTECH , (d ) no se requiere
        ningún consentimiento o aprobación que no se haya obtenido o no se espere obtener de ninguna
        persona o autoridad pública para autorizar, o se requiera en relación con, la ejecución, del
        entrega o ejecución de este Acuerdo por parte de IMTECH (e) el Software con licencia, como
        tal, se entrega o se pone a disposición del Cliente y, cuando se usa según lo contemplado en
        este Acuerdo, no se apropia indebidamente, infringe o viola de otro modo los derechos de
        propiedad intelectual de cualquier tercero.
    </p>
    <p class="black-16 justify-text pa-b-20">
        6.2 Garantías del Cliente. El Cliente declara y garantiza a IMTECH lo siguiente a partir de la
        Fecha de entrada en vigencia y en adelante, con la intención de que dichas representaciones y
        garantías sobrevivan a la Fecha de entrada en vigencia: (a) El Cliente está debidamente
        organizado, tiene una existencia válida y está al corriente de conformidad con las leyes del
        estado. de formación del Cliente y posee el poder de celebrar este Acuerdo y cumplir con los
        términos y condiciones de este Acuerdo sin violar ningún otro acuerdo del que sea parte en
        cualquier aspecto material, (b) la ejecución, entrega y ejecución de este Acuerdo, y las
        transacciones contempladas por este Acuerdo, por parte del Cliente han sido debida y
        válidamente autorizadas y aprobadas por todas las acciones requeridas por parte del Cliente, (c)
        este Acuerdo es una obligación legal, válida y vinculante del Cliente, (d ) no se requiere ningún
        consentimiento o aprobación que no se haya obtenido o no se espere obtener de ninguna
        persona o autoridad pública para autorizar, o se requiera en relación con, el exe ejecución,
        entrega o ejecución de este Acuerdo por parte del Cliente, y (e) el Cliente no permitirá que ningún
        competidor de IMTECH vea, use, acceda o copie la propiedad intelectual de IMTECH , incluido
        el Software con licencia.
    </p>
    <h4 class="blu-16 pa-b-20">
        7. Indemnización; Limitación de responsabilidad.
    </h4>
    <p class="black-16 justify-text pa-b-20">
        7.1 Sujeto a las siguientes limitaciones de responsabilidad, IMTECH reembolsará, defenderá,
        indemnizará y eximirá de responsabilidad al Cliente y a los afiliados del Cliente y a los socios,
        miembros, accionistas, propietarios, gerentes, directores, asesores, funcionarios, empleados,
        contratistas, agentes, subsidiarias, sucesores y cesionarios por, de y contra cualquier reclamo,
        daño, pérdida, deficiencia, responsabilidad, multa, cargo, costo y gasto que resulte de, se
        relacione o surja de (a) negligencia o actos u omisiones intencionalmente ilícitos de IMTECH
        (incluido el personal de IMTECH ) en violación de los términos del Acuerdo o, y (b) cualquier
        reclamo de que el Software con licencia, tal como se entrega en virtud de este Acuerdo, infringe
        o se apropia indebidamente de cualquier derecho de propiedad intelectual de un tercero -parte
        (una "Reclamación por infracción"). LO ANTERIOR ESTABLECE TODA LA RESPONSABILIDAD
        DE IMTECH Y EL RECURSO EXCLUSIVO DEL CLIENTE PARA UNA RECLAMACIÓN DE
        INFRACCIÓN. EL CLIENTE RENUNCIA EXPRESAMENTE A CUALQUIER OTRA
        RESPONSABILIDAD POR PARTE DE IMTECH DERIVADA DE ESTO. IMTECH no tendrá
        ninguna responsabilidad u obligación en virtud de esta Sección por ningún reclamo basado en:
        (I) el uso de cualquier parte del Equipo o el Software Licenciado en combinación con materiales,
        equipo o herramientas no proporcionados por IMTECH ; o, (II) modificaciones realizadas por el
        Cliente o cualquier tercero o cualquier uso distinto al expresamente autorizado en este
        documento.
    </p>
    <p class="black-16 justify-text pa-b-20">
        7.2 El Cliente deberá reembolsar, defender, indemnizar y eximir de responsabilidad a IMTECH
        y a las filiales de IMTECH y a los socios, miembros, accionistas, propietarios, gerentes,
        directores, asesores, funcionarios, empleados, contratistas, agentes, subsidiarias, sucesores y
        cesionarios de IMTECH y las filiales de IMTECH, por, de y contra todos y cada uno de los
        reclamos, daños, pérdidas, deficiencias, responsabilidades, sanciones, cargos, costos y gastos
        que resulten de, se relacionen o surjan de (a) actos u omisiones del Cliente (incluido el personal
        del Cliente ) en violación de los términos de este Acuerdo, (b) uso no autorizado, inapropiado o
        ilegal por parte del Cliente (incluido el personal del Cliente) del Software Licenciado en violación
        de los términos de este Acuerdo, (c) uso por parte de otro del Software Licenciado , utilizando la
        cuenta del Cliente, en violación de los términos de este Acuerdo, (d) el incumplimiento por parte
        del Cliente de cualquier pacto, acuerdo, representación o garantía establecida en este Acuerdo,
        o (e) la inexactitud o errores en la información proporcionada.
    </p>
    <p class="black-16 justify-text pa-b-20">
        7.3 Las obligaciones de indemnización de cada una de las partes estarán condicionadas a lo
        siguiente: (a) la parte indemnizada deberá proporcionar una notificación por escrito inmediata de
        cualquier reclamación a la parte que indemnice, siempre que la falta de notificación por escrito
        con prontitud solo liberará a la parte que indemniza de sus obligaciones en la medida en que su
        capacidad para defender tal reclamo se vea materialmente perjudicada por dicho incumplimiento;
        (b) la parte indemnizadora no dará su consentimiento para que se dicte ningún fallo ni se admita
        ninguna responsabilidad de la parte indemnizada sin la aprobación previa por escrito de la parte
        indemnizada; y (c) la parte indemnizada cooperará en la defensa de cualquier reclamo según lo
        requiera razonablemente la parte indemnizadora a expensas exclusivas de la parte
        indemnizadora. Se reconoce y acuerda que nada de lo aquí contenido (i) se considerará una
        renuncia por cualquiera de las partes, a cualquier remedio o derecho, en derecho o equidad,
        todos los cuales se reservan expresamente sin perjuicio; y (ii) impide que la parte indemnizada
        obtenga un abogado por separado a expensas exclusivas de dicha parte.
    </p>
    <p class="black-16 justify-text pa-b-20">
        7.4 EN NINGÚN CASO IMTECH SERÁ RESPONSABLE ANTE EL CLIENTE O TERCEROS
        POR CUALQUIER PÉRDIDA DE USO, INGRESOS O BENEFICIOS O PÉRDIDA DE DATOS O
        DISMINUCIÓN DE VALOR, O POR CUALQUIER DAÑO CONSECUENTE, INCIDENTAL,
        INDIRECTO, EJEMPLAR, ESPECIAL O PUNITIVO YA SEA DERIVADOS DEL
        INCUMPLIMIENTO DEL CONTRATO, AGRAVIO (INCLUIDA LA NEGLIGENCIA) O DE
        CUALQUIER DAÑO, INDEPENDIENTEMENTE DE SI DICHOS DAÑOS FUERON
        PREVISIBLES Y DE SI IMTECH HA SIDO ADVERTIDO O NO DE LA POSIBILIDAD DE
        DICHOS DAÑOS, Y A PESAR DE LA FALTA DE CUALQUIER RECURSO ACORDADO U OTRO
        DE SU ESENCIAL PROPÓSITO.
    </p>
    <p class="black-16 justify-text pa-b-20">
        7.5 EN NINGÚN CASO LA RESPONSABILIDAD TOTAL DE IMTECH DERIVADA O
        RELACIONADA CON ESTE ACUERDO, YA SEA POR INCUMPLIMIENTO DE CONTRATO,
        AGRAVIO (INCLUIDA NEGLIGENCIA) O DE CUALQUIER OTRA FORMA, EXCEDERÁ LAS
        CANTIDADES TOTALES PAGADAS A IMTECH DE CONFORMIDAD CON ESTE ACUERDO
        EN LOS 12 MESES PREVIO AL HECHO QUE DA LUGAR AL RECLAMO.
    </p>
    <p class="black-16 justify-text pa-b-20">
        8. Otorgamiento de licencias. Durante el Plazo, las Partes se otorgan una licencia limitada y no
        exclusiva para usar las marcas comerciales que cada una de ellas designe ocasionalmente, con
        el fin específico y limitado de comercializar y promover públicamente el Equipo. 
    </p>
    <p class="black-16 justify-text pa-b-20">
        9. Aviso. Cualquier notificación u otra comunicación requerida o permitida en virtud de este
        Acuerdo debe ser entregada mediante: (a) por correo electrónico o (b) llamada telefónica. Cada
        notificación o comunicación que se envíe entregue o transmita será debidamente formal si se
        entrega en horario laboral o bien, a primera hora del día laboral más próximo. Cualquiera de las
        Partes podrá cambiar su dirección mediante notificación por cualquiera de los medios ya
        establecidos. La información del aviso de IMTECH se encuentra a continuación, y la dirección
        del aviso del Cliente se considerará como la dirección de instalación del Cliente.
    </p>
    <h4 class="blu-16 pa-b-20">
        IMTECH
    </h4>
    <h4 class="blu-16 pa-b-20">
        Dirección: IMTECH DESARROLLOS SAPI DE CV<br>
        Calle Pedro Vargas #7515<br>
        Col. Churubusco<br>
        Chihuahua, Chih.<br>
        Apartado Postal 31120<br><br>
        Dirección web: gawi.mx<br><br>
        Número de teléfono: 6144178189
    </h4>
    <h4 class="blu-16 pa-b-20">
        Correo electrónico: contacto@gawi.mx
    </h4>
    <p class="black-16 justify-text pa-b-20">
        9.2 Relación de Partes. Nada en este Acuerdo creará o se considerará que crea la relación de
        socios, empresas conjuntas, empleador-empleado, o empleados conjuntos entre las Partes.
        Ninguna de las Partes tendrá autoridad para asumir o crear ninguna obligación o responsabilidad,
        expresa o implícita, en nombre de la otra Parte. Cada Parte hará esfuerzos comercialmente
        razonables para cooperar y trabajar de buena fe con la otra Parte en relación con las
        transacciones contempladas en este Acuerdo.
    </p>
    <p class="black-16 justify-text pa-b-20">
        9.3 Renuncia. Ninguna renuncia de cualquiera de las Partes a cualquiera de las disposiciones de
        este Acuerdo será efectiva a menos que se establezca explícitamente por el medio pactado.
        Salvo que se establezca lo contrario en este Acuerdo, ninguna falta de ejercicio o retraso en el
        ejercicio de cualquier derecho, recurso, poder o privilegio que surja de este Acuerdo operará o
        se interpretará como una renuncia al mismo, ni el ejercicio único o parcial de cualquier derecho,
        poder o privilegio en virtud del presente excluyen cualquier otro o más ejercicio del mismo o el
        ejercicio de cualquier otro derecho, poder o privilegio.
    </p>
    <p class="black-16 justify-text pa-b-20">
        9.4 Sucesores y cesionarios. Este Acuerdo será vinculante y redundará en beneficio de los
        sucesores y cesionarios permitidos de cada Parte del presente. Ninguna de las Partes podrá
        ceder este Acuerdo o cualquiera de sus derechos en virtud del mismo sin el consentimiento previo
        de la otra Parte, cuyo consentimiento no se denegará ni demorará injustificadamente, y cualquier
        intento de cesión sin dicho consentimiento será nulo; sin embargo, IMTECH pueda ceder este
        Acuerdo a una afiliada, subsidiaria o sucesora (por fusión o de pleno derecho) o en relación con
        la venta de sus activos, capital o cualquier cambio de control.
    </p>
    <p class="black-16 justify-text pa-b-20">
        9.5 Sin Terceros Beneficiarios. Este Acuerdo beneficia únicamente a las Partes de este Acuerdo
        y sus respectivos sucesores y cesionarios permitidos y nada en este Acuerdo, expreso o
        implícito, confiere a ninguna otra Persona ningún derecho, beneficio o recurso legal o equitativo
        de cualquier naturaleza en virtud o en virtud de este Acuerdo.
    </p>
    <p class="black-16 justify-text pa-b-20">
        9.6 Caso fortuito o fuerza mayor. IMTECH no será responsable ante el Cliente, ni se considerará
        que ha incumplido o incumplido este Acuerdo, por cualquier falla o demora en el cumplimiento o
        ejecución de cualquier término de este Acuerdo cuando y en la medida en que dicha falla o
        demora sea causada por o resulte de actos o circunstancias más allá del control razonable de
        IMTECH , incluidos inundaciones, incendios, terremotos, vientos fuertes, explosiones, acciones
        gubernamentales, conflicto armado u disturbios civiles , emergencia nacional, epidemia, cierres
        patronales, huelgas u otros conflictos laborales (relacionados o no con la mano de obra de
        cualquiera de las partes), o restricciones o retrasos que afecten a los transportistas o incapacidad
        o retraso en la obtención de suministros de materiales adecuados, materiales o falla de
        telecomunicaciones o corte de energía, siempre que, si el evento en cuestión continúa por un
        período continuo de más de 180 días, el Cliente tendrá derecho a dar aviso por escrito a IMTECH
        para rescindir este Acuerdo, en los términos de la cláusula 5.2.
    </p>
</section>