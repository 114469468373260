import {Component, OnInit} from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import {Router, NavigationEnd} from '@angular/router';
import {ApiDataService} from 'src/app/services/apiData/api-data.service';
import {UtilitiesService} from 'src/app/services/utilities/utilities.service';


@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.css'],
})
export class RecoveryPasswordComponent implements OnInit {
  awaitAnimation: Boolean;
  recoveryForm: FormGroup;
  re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private apiDataService: ApiDataService,
    private utilitiesService: UtilitiesService,
  ) {
    this.recoveryForm = this.formBuilder.group({
      email: ['', [Validators.email, Validators.required]],
      password: ['', [Validators.minLength(8), Validators.required]],
      retypedPassword: ['', [Validators.minLength(8), Validators.required]],
    });
    this.awaitAnimation = false;
  }

  ngOnInit(): void {}

  public recoverPassword() {
    const token = this.router.url.split('/')[2];
    const email = this.recoveryForm.value.email;
    const pswrd = this.recoveryForm.value.password;

    this.apiDataService.changePassword(email, pswrd, token)
    .then((resp: any) => {
      this.utilitiesService.showSuccessToast(resp.message, "Éxito");
      this.router.navigateByUrl('/login');
    })
    .catch((err: any) => {
      this.utilitiesService.showErrorToast(err.message, "Error");
      this.router.navigateByUrl('/login');
    });
  }

  public checkCredentials() {
    const email = this.recoveryForm.value.email;
    const newPsw = this.recoveryForm.value.password;
    const retype = this.recoveryForm.value.retypedPassword;
    const ansPsw =
      newPsw === retype && newPsw != '' && retype != '' ? true : false;
    const ansEmail = this.re.test(email.toLowerCase());
    return ansEmail && ansPsw;
  }

  get email(): AbstractControl {
    return this.recoveryForm.get('email');
  }

  get password(): AbstractControl {
    return this.recoveryForm.get('password');
  }

  get retypedPassword(): AbstractControl {
    return this.recoveryForm.get('retypedPassword');
  }
}
