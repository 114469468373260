import { Injectable } from '@angular/core';
import { deployConf } from './../../utils/config';
import { HttpClient, HttpHeaders, HttpEvent, HttpRequest } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { timeout, catchError } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { ServerMessage } from '../../classes/serverMessage.class';
import { User } from '../../classes/user.class';
import { ApnDto } from '../../classes/apn.class';
import { Organization } from '../..//classes/organization.class';


@Injectable({
  providedIn: 'root'
})
export class ApiDataService {
  baseURL: string = deployConf.apiUrl;
  token: String;

  //52 endpoints web (8 meses) + 67 end points movil (8 meses) + 7 rutas para medidores (5 meses)  = 119 end points totales
  //La primera que se realizaron los envios de informacion y logue se termino en 3 semanas (1 mes a lo mucho)
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  setToken(newToken: String) {
    this.token = newToken;
  }

  getImage(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<any>(url, { headers: headers, responseType: 'blob' as 'json' })
        .pipe(
          timeout(2000),
          catchError(e => {
            // do something on a timeout
            //reject(e);
            return of(null);
          })
        ).subscribe((imageBlob) => {
          let objectURL = "";
          if (imageBlob != null && imageBlob != undefined) {
            objectURL = URL.createObjectURL(imageBlob);
          }
          resolve(this.sanitizer.bypassSecurityTrustUrl(objectURL));
        }, (error: ServerMessage) => {
          reject(error)
        });
    })
  }

  async uploadImageBrand(formData: FormData): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'uploads/brand-image/', formData, { headers: headers })
        .subscribe((res: ServerMessage) => {
          if (res.error == false) {
            resolve(res);
          } else if (res.error == undefined) {
            console.log("error no llego nada");
            reject(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }

  //USER END-POINTS
  doLogin(email: String, password: String): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const data = { email: email, password: password };

      this.http.post<ServerMessage>(this.baseURL + 'auth/login', data, {}).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


  doResetPassword(recoverEmail: string): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      this.http.post<ServerMessage>(
        this.baseURL + 'public/reset-password', { recoverEmail: recoverEmail }, {}).subscribe((response: ServerMessage) => {
          resolve(response);
        }, (error: any) => {
          reject(error);
        });
    });
  }


  getUserData(token: String): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      })

      this.http.get<ServerMessage>(this.baseURL + 'auth/validate-token', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateUserAdminData(newUserData: User): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-user-data', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async createNewProfileUser(newUserData: {
    fullName: string,
    telephone: string,
    userName: string,
    email: string,
    password: string,
    confirmPass: string,
    profileType: string,
    noAgent: number,
    acceptTerms: string,
    recaptcha: string
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/create-user-profile', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


  //ADMINISTRATOR END-POINTS

  //Admin Organization
  getOrganizationAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-organization-settings-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -All devices
  getAllDevicesData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-devices-list', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //Individual device gas history
  getAllHistoryNaturalGasDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
    period: number,
    serialNumbersExtraDevices: string[]
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-natural-gas-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //Individual device water history
  getAllHistoryWaterDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
    period: number,
    serialNumbersExtraDevices: string[]
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-water-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  updateApnDeviceData( updatedData : {
    idDevice: number,
    idApn: number,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-device-apn', updatedData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  unlockDeviceToBeAssigned( updatedData : {
    idDevice: number,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/unlock-assigned-device', updatedData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //Individual device logger history
  getAllHistoryLoggerDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-logger-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //Individual device gas history for administrator
  getAllHistoryGasDeviceData(queryData: {
    idDevice: number,
    fromDate: Date,
    toDate: Date,
    period: number,
  }): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/get-all-history-gas-device', queryData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  
  ////Admin My Organization : 

  //Root admin data
  getHomeAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-home-admin', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -All organization devices
  getAllOrganizationDevicesData(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-organization-devices-list/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Users Technician
  getOrganizationTechnicianAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-technician-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Users Counters
  getOrganizationCountersAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-counters-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Users Warehouses
  getOrganizationWarehousesAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-warehouse-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Users Clients
  getOrganizationClientsAdminData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-clients-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  getOrganizationClientProfileAdminData(idUser: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-client-profile-data/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  //  -All platform users
  getAllAccountUsersData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-account-users-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -All Users CRUD for Admin
  async createNewUserAdmin(newUserData: User): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/create-user', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateUserAdmin(newUserData: User): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/update-user', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteUserAdmin(idUser: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/delete-user/' + idUser, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  ////WAREHOUSE : 
  //  -Home page data load
  async loadHomeDataWarehouse(): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/get-home-data', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  //  -Water Devices 
  async getApnCatalogList(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/get-apn-list', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async createNewDeviceWarehouse(newUserData: { imei: string, serialNumber: string, type: number , idApn : number, }): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/create-device', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  async createNewDevicesByArrayWarehouse(newDevicesData: { imei: string, serialNumber: string, type: number , idApn : number,}[]): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/create-multiple-devices', newDevicesData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  // ADMIN ASSIGNMENTS 
  async manualAssignment( data : [{
    serialNumber: string,
    type: number,
    idOrganization: number,
  }] ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/manual-assignments';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, data ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  // ADMIN PAYMENTS 
  async manualSubscriptionsActivations( data : [{
    serialNumber: string,
    type: number,
    years: number,
  }] ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/manual-subscriptions-activations';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, data ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  async getAdminSubscriptionPayments( queryParams : any ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/get-subscription-payment-list';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, queryParams ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }
  async doSubscriptionBill( data: { idHistoryPayment: number } ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/create-invoice';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, data ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  async getAdminBills( queryParams : any ): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/payments/get-already-bills-list';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, queryParams ,{ headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  // ADMIN SETTINGS
  async getAdminSettingViewData(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/admin-data/get-admin-settings-data';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.get<ServerMessage>(url, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  async updateAdminProfileData(adminUserData: any): Promise<ServerMessage> {
    return new Promise(async (resolve, reject) => {
      const url: string = this.baseURL + 'administrator/admin-data/update-admin-profile-data';
      const headers: any = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      });

      this.http.post<ServerMessage>(url, adminUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error);
      });
    });
  }

  //Verify device data
  async checkAlreadyDevice(newUserData: { serialNumber: string, type: number }): Promise<any> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'wharehouse-devices-admin/check-already-device', newUserData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /* APN END POINTS */
  async getApnList(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.get<ServerMessage>(this.baseURL + 'administrator/admin-data/get-apn-list', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  async createNewAPNAdmin(newApnData: ApnDto): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/admin-data/create-new-apn', newApnData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async updateAPNAdmin(apnData: ApnDto): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/admin-data/update-apn', apnData, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async deleteAPNAdmin(apnDataToDelete: ApnDto, apnDataToSet: ApnDto ): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(this.baseURL + 'administrator/admin-data/delete-apn', {
        apnDataToDelete : apnDataToDelete,
        apnDataToSet : apnDataToSet,
      }, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // get gas alerts
  getGasDeviceAlerts(
    idDevice: number,
    period: number
  ): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {  
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/get-gas-device-alerts/' + idDevice + '/' + period,
        { headers: headers }).subscribe((response: ServerMessage) => {
          resolve(response);
        }, (error) => {
          reject(error)
        });
    })
  }

  // get water alerts
  getWaterDeviceAlerts(
    idDevice: number,
    period: number
  ): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {  
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/get-water-device-alerts/' + idDevice + '/' + period,
        { headers: headers }).subscribe((response: ServerMessage) => {
          resolve(response);
        }, (error) => {
          reject(error)
        });
    })
  }

  /**
   * 
   */
  getOrganizationsData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-organizations-data', {
        headers: headers
      }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * 
   */
  getOrganizationsListData(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-organizations-list-data', {
        headers: headers
      }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * @TODO cambiar al controlador de admin/organization
   */
   async updateOrganizationData(organizationData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(
        this.baseURL + 'administrator/update-organization-data', organizationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
  async getOrganizationAdminChoices(choice: string): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'administrator/get-organization-admin-choices/' + choice, {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
   async getOrganizationAdmin(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/get-organization-admin/' + idOrganization, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * @TODO cambiar al controlador de admin/organization
   */
   async createOrganization(organizationData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(
        this.baseURL + 'administrator/create-organization', organizationData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  async uploadLogoFileOrganization(fileData: FormData): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post(this.baseURL + 'logo-images-uploads/add-logo-image-file', fileData, { headers: headers })
        .subscribe(( res: any ) => {
          if (res == null || res == undefined) {
            console.log("error no llego nada");
            reject(res);
          } else if (res.error == null || res.error == undefined || res.error == false) {
            resolve(res);
          } else {
            resolve(res);
          }
        }, (error) => {
          reject(error);
        });
    });
  }


  async downloadLogo(url: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.baseURL + url, {responseType: 'blob'})
      .subscribe(
        (response: any) => {
        if (response == null || response == undefined)
          reject(response);
        else {
          resolve(response);
        }
      }, (error: any) => {
        console.log(error);      
      });
    }); 
  }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
   async deleteOrganization(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.get<ServerMessage>(
        this.baseURL + 'administrator/delete-organization/' + idOrganization, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }


 /**
  * Obtener todas las organizaciones contenidas en la base de datos
  * @returns todas las organizaciones contenidas en la base de datos excepto la principal 
  */
  async getAllOrganizations(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-all-organizations', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * 
  * @returns 
  */
  async getDevicesInStock(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-devs-in-stock', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * 
  * @returns 
  */
  async getDevicesOnDemmand(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-devs-on-demmand', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
 }


 /**
  * @TODO cambiar al controlador de admin/organization
  */
  async createDepartureOrder(departureOrderData: any): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });

      this.http.post<ServerMessage>(
        this.baseURL + 'production/departure-orders/create-order', departureOrderData, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * 
   * @param idDepartureOrder 
   * @returns 
   */
  async cancelDepartureOrder(idDepartureOrder: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.get<ServerMessage>(
        this.baseURL + 'production/departure-orders/cancel-order/' + idDepartureOrder, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  /**
   * 
   * @param idDepartureOrder 
   * @returns 
   */
  async completeDepartureOrder(idDepartureOrder: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token,
      });
 
      this.http.get<ServerMessage>(
        this.baseURL + 'production/departure-orders/complete-order/' + idDepartureOrder, {
          headers: headers
        }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

 /**
  * 
  * @returns 
  */
  async getAllDepartureOrders(): Promise<ServerMessage> {
   return new Promise((resolve, reject) => {
     const headers = new HttpHeaders({
       'Content-Type': 'application/json',
       'Authorization': 'Bearer ' + this.token,
     });

     this.http.get<ServerMessage>(
       this.baseURL + 'production/departure-orders/get-all-orders', {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
  }

 /**
  * 
  * @returns 
  */
  async changePassword(email: string, pswrd: string, token: string): Promise<ServerMessage> {
    const credentials = {
      email: email,
      password: pswrd,
    };

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
      });

     this.http.post<ServerMessage>(
       this.baseURL + 'user/change-password', credentials, {
         headers: headers
       }).subscribe((response: ServerMessage) => {
       resolve(response);
     }, (error) => {
       reject(error)
     });
   })
  }

  //Categorias y sub categorias
  /*
  */
  getCategories(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-categories-and-subcategories/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  getCategories2(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-categories/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // Creacion de categorias por organizacion
  createCategories(name: string, idPadre: number, idOrganization: number, color: string): Promise<ServerMessage> {
    const category = {
      name:name,
      idPadre: idPadre,
      idOrganization: idOrganization,
      color: color,
    }
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.post<ServerMessage>(this.baseURL + 'organization/organization-data/create-category',category, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // Actualizacion de categorias ya existentes
  updateCategories(idCategory: number, name: string, idPadre: number, idOrganization: number): Promise<ServerMessage> {
    const category = {
      name:name,
      idPadre: idPadre,
      idOrganization: idOrganization,
    }
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.patch<ServerMessage>(this.baseURL + 'organization/organization-data/edit-category/' + idCategory, category, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // Deshabilita categorias, para el lado del usuario se "elimina"
  disableCategories(idCategory: number): Promise<ServerMessage> {

    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.patch<ServerMessage>(this.baseURL + 'organization/organization-data/disable-category/' + idCategory, {}, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todas las categorias de un dispositivo
  getDevicesAllCategories(idOrganization: number, initialLastYearStaticDate: Date, endLastYearStaticDate: Date, initialActualYearStaticDate: Date, endActualYearStaticDate: Date, initialLastYearDate: Date, endLastYearDate: Date, initialDate: Date, endDate: Date, initialLastWeekDate: Date){
    const Organization = {
      idOrganization:idOrganization,
      initialLastYearStaticDate: initialLastYearStaticDate,
      endLastYearStaticDate: endLastYearStaticDate,
      initialActualYearStaticDate: initialActualYearStaticDate,
      endActualYearStaticDate: endActualYearStaticDate,
      initialLastYearDate: initialLastYearDate,
      endLastYearDate: endLastYearDate,
      initialDate:initialDate,
      endDate: endDate,
      initialLastWeekDate: initialLastWeekDate,
    }
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })

    this.http.post<ServerMessage>(this.baseURL + 'organization/organization-data/find-devices-allcategory', Organization ,{headers: headers}).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        console.log(error)
        reject(error)
      });

    })
  }

  // Obtiene los dispositivos que pertenecen a una o mas categorias
  getDevicesByCategory(idOrganization: number, initialLastYearStaticDate: Date, endLastYearStaticDate: Date, initialActualYearStaticDate: Date, endActualYearStaticDate: Date ,categories: string[], numberSubcategories: number,initialLastYearDate: Date, endLastYearDate: Date, initialDate: Date, endDate: Date, initialLastWeekDate: Date){
    const Organization = {
      idOrganization:idOrganization,
      initialLastYearStaticDate: initialLastYearStaticDate,
      endLastYearStaticDate: endLastYearStaticDate,
      initialActualYearStaticDate: initialActualYearStaticDate,
      endActualYearStaticDate: endActualYearStaticDate,
      categories: categories,
      numberSubcategories: numberSubcategories,
      initialLastYearDate: initialLastYearDate,
      endLastYearDate: endLastYearDate,
      initialDate:initialDate,
      endDate: endDate,
      initialLastWeekDate: initialLastWeekDate,
    }
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })

    this.http.post<ServerMessage>(this.baseURL + 'organization/organization-data/find-devices-category', Organization ,{headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todos los dispositivos de agua para buscador/autocomplete del dashboard principal
  getDevicesWater(idOrganization: number){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/devices-byOrganization/' + idOrganization ,{headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene la informacion de un solo dispositivo seleccionado en el dashboard principal
  getDeviceWaterData(idOrganization: number, idDevice:number, initialLastYearStaticDate: Date, endLastYearStaticDate: Date, initialActualYearStaticDate: Date, endActualYearStaticDate: Date  ,initialLastYearDate: Date, endLastYearDate: Date, initialDate: Date, endDate: Date, initialLastWeekDate: Date){
    const Organization = {
      idOrganization:idOrganization,
      idDevice: idDevice,
      initialLastYearStaticDate: initialLastYearStaticDate,
      endLastYearStaticDate: endLastYearStaticDate,
      initialActualYearStaticDate: initialActualYearStaticDate,
      endActualYearStaticDate: endActualYearStaticDate,
      initialLastYearDate: initialLastYearDate,
      endLastYearDate: endLastYearDate,
      initialDate:initialDate,
      endDate: endDate,
      initialLastWeekDate: initialLastWeekDate,
    }
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })

    this.http.post<ServerMessage>(this.baseURL + 'organization/organization-data/device-water-history', Organization ,{headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene las categorias que posee un solo dispositivo
  getDeviceCategories(idOrganization: number, idDevice:number){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-categories-byDevice/' + idOrganization + '/' + idDevice, {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Asigna categorias a un dispositivo que previamente no tenia
  assignCategoriesDevice(idDevice: number, categories: number[]){
    const Organization = {
      idDevice: idDevice,
      categories: categories,
    }

    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.post<ServerMessage>(this.baseURL + 'organization/organization-data/assing-categories-to-device', Organization, {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Edita las categorias de un dispositivo
  editCategoriesDevice(idDevice: number, categoriesToDelete: number[], categoriesToCreate: number[]){
    const Organization = {
      idDevice: idDevice,
      categoriesToDelete: categoriesToDelete,
      categoriesToCreate: categoriesToCreate,
    }

    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.patch<ServerMessage>(this.baseURL + 'organization/organization-data/edit-categories-to-device', Organization, {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Quitas las categorias asignadas a un dispositivo
  deleteCategoriesDevice(idDevice: number, categoriesToDelete: number[]){
    const Organization = {
      idDevice: idDevice,
      categoriesToDelete: categoriesToDelete,
    }

    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.patch<ServerMessage>(this.baseURL + 'organization/organization-data/delete-categories-to-device', Organization, {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene toda la informacion del nuevo dashboard de gas
  getGasDashboard(idOrganization: number,startDate: string, endDate: string){

    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-gas-devices/' + idOrganization + '/' + startDate + '/' + endDate,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todas las alertas, por tipo de alerta de cada dispositivo
  getGasAlertsBydevice(idOrganization: number,startDate: string, endDate: string, type: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-alerts-device/' + idOrganization + '/' + startDate + '/' + endDate + '/' + type,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene los datos para armar el excel de todos los dispositivos de gas
  getGasExcelDashboard(idOrganization: number,startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/generate-excel/' + idOrganization + '/' + startDate + '/' + endDate,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todos los datos de un solo dia(turno) de un dispositivo de gas
  getIndividualGasShift(idDevice: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-gas-device/' + idDevice + '/' + startDate + '/' + endDate,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todos los datos por rango de fechas de un dispositivo de gas
  getIndividualGas(idDevice: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-gas-device-data/' + idDevice + '/' + startDate + '/' + endDate,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todas las alertas de todos los dispositivos de gas
  getAllGasAlerts(idOrganization: number){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-alerts/' + idOrganization,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todas las alertas de un dispositivo de gas
  getAllGasDeviceAlerts(idDevice: number){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-all-alerts-device/' + idDevice,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todas las configuraciones ya establecidas de notificaciones de cada dispositivo (dashboard gas)
  getConfigurationAlarms(idDevice: number){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-alarm-settings/' + idDevice,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Establece la nueva configuraciones de notificaciones deseada por el usuario
  configurationAlarms(idDevice: number, timeSetPoint: string, highMeasureSetPoint: number, highMeasureFlag: number, openHour: string, nextFillSetPoint: number, lowBaterySetPoint: number, outOfTimeFlag: number, maxMeasureFlag: number, minMeasureFlag: number, nextFillFlag, lowSignalFlag: number, lowBateryFlag: number, smsFlag: number, emailFlag: number){
    const config = {
      idDevice: idDevice,
      timeSetPoint: timeSetPoint,
      highMeasureSetPoint: highMeasureSetPoint,
      highMeasureFlag : highMeasureFlag,
      openHour: openHour,
      nextFillSetPoint: nextFillSetPoint,
      lowBaterySetPoint: lowBaterySetPoint,
      outOfTimeFlag: outOfTimeFlag,
      maxMeasureFlag: maxMeasureFlag,
      minMeasureFlag: minMeasureFlag,
      nextFillFlag: nextFillFlag,
      lowSignalFlag: lowSignalFlag,
      lowBateryFlag: lowBateryFlag,
      smsFlag: smsFlag,
      emailFlag: emailFlag
    }

    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.patch<ServerMessage>(this.baseURL + 'organization/organization-data/edit-alarm-settings', config , {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todos los dispositivos de agua
  getWaterDevices(idDevice: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-organization-water-devices/' + idDevice + '/' + startDate + '/' + endDate,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

    // Obtiene el numero total de alertas de los dispositivos de agua
    getWaterDevicesAlertsNumber(idOrganization: number){
      return new Promise((resolve, reject)=>{
        const headers = new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.token
      })    
  
      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-organization-water-devices-number-alerts/' + idOrganization,  {headers: headers}).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
          console.log(error)
          reject(error)
        });
      })
    }  
 
  // Obtiene todas las alertas de los dispositivos de Agua
  getWaterDevicesAlerts(idOrganization: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-organization-water-devices-all-alerts/' + idOrganization + '/' + startDate + '/' + endDate,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }  

  // Obtiene toda la informacion de un dispositivo de agua
  getWaterIndividualDevice(idDevice: number, period:string,startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-water-device/' + idDevice + '/' + period + '/' + startDate + '/' + endDate,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene el numero de alertas a mostrar como numero de un dispositivo
  getNumberAlertsWaterIndividualDevice(idDevice: number){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-water-device-number-alerts/' + idDevice,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Obtiene todas las alertas en un rango de fechas
  getAlertsWaterIndividualDevice(idDevice: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/get-water-device-all-alerts/' + idDevice + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  /* Superadmin agua
  *
  */

  // Dashboard de gas
  getSuperadminGas(idOrganization: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/gas/get-organization-gas-devices/' + idOrganization + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Dashboard de gas transmisiones general
  getAllTransmissionsSuperadminGas(startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/gas/get-all-gas-transmissions/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Dashboard de gas transmisiones individual
  getIndividualTransmissionsSuperadminGas(idDevice: string,startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/gas/get-gas-device-transmissions/'+ idDevice + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  
  // Datos dispositivo individual superadmin de gas
  getIndividualGasDeviceSuperadmin(idDevice: number, period: string ,startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/gas/get-gas-device-info/'+ idDevice + '/' + period + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Dashboard de gas transmisiones individual
  getAllAlertSuperadminGas(startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/gas/get-all-gas-alarms/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Dashboard de gas transmisiones individual
  getIndividualAlertSuperadminGas(idDevice: string, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/gas/get-gas-device-alarms/' + idDevice + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Dashboard de gas transmisiones individual
  getSpecificationsSuperadminGas(idDevice: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/gas/get-gas-device-description/' + idDevice ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }  

  /* Superadmin Agua
  *
  */

  //Todos los dispositivos de agua
  getSuperadminWater(idOrganization: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/water/get-organization-water-devices/' + idOrganization + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  // Info general individual de agua
  getSuperadminIndividualWater(idDevice: number, period: string, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/water/get-water-device-info/' + idDevice + '/' + period + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  //Transmisiones generales todos los dispositivos agua
  getAllTransmissionsSuperadminWater(startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/water/get-all-water-transmissions/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  //Transmisiones individuales de dispositivos de agua
  getIndividualTransmissionsSuperadminWater(idDevice: number,startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/water/get-individual-water-transmissions/' + idDevice + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  //Alarmas generales todos los dispositivos agua
  getAllAlarmsSuperadminWater(startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/water/get-all-water-alarms/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  //Alarmas de un solo dispositivo de agua
  getIndividualAlarmSuperadminWater(idDevice: number, startDate: string, endDate: string){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/water/get-water-device-alarms/' + idDevice + '/' + startDate + '/' + endDate ,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }

  //Transmisiones individuales de dispositivos de agua
  getSpecificationsSuperadminWater(idDevice: number){
    return new Promise((resolve, reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
    })    

    this.http.get<ServerMessage>(this.baseURL + 'administrator/water/get-water-device-description/' + idDevice,  {headers: headers}).subscribe((response: ServerMessage) => {
      resolve(response);
    }, (error) => {
        console.log(error)
        reject(error)
      });
    })
  }


  // getGasDeviceAlerts(
  //   idDevice: number,
  //   period: number
  // ): Promise<ServerMessage> {
  //   return new Promise((resolve, reject) => {  
  //     const headers = new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': 'Bearer ' + this.token
  //     })

  //     this.http.get<ServerMessage>(
  //       this.baseURL + 'administrator/get-gas-device-alerts/' + idDevice + '/' + period,
  //       { headers: headers }).subscribe((response: ServerMessage) => {
  //         resolve(response);
  //       }, (error) => {
  //         reject(error)
  //       });
  //   })
  // }


  //TO DO : Implementacion Servidor
  /*   async uploadImageUser(formData: FormData) {
      return new Promise((resolve,reject)=>{
        const headers = new HttpHeaders({
          'Authorization': 'Bearer '+this.token,
        });
  
        this.http.post<ServerMessage>(this.baseURL + 'uploads/user-image/', formData, {headers:headers })
          .subscribe((res: ServerMessage) => {
            if (res.error == false) {
              resolve(res);
            } else if( res.error == undefined){
              console.log("error no llego nada");
              reject(res);
            }else{
              resolve(res);
            }
          },(error)=>{
            reject(error);
          },);
      });
    } */

  //TO DO : Implementacion Servidor
  /*   deleteImageUser(idUser){
      return new Promise((resolve,reject)=>{
        const headers = new HttpHeaders({
          'Authorization': 'Bearer '+this.token,
        });
        this.http.get<ServerMessage>(this.baseURL + 'uploads/user-delete-image/'+idUser,{headers:headers}).subscribe((response : ServerMessage)=>{
          resolve(response);
        },(error)=>{
          reject(new ServerMessage(true,"A ocurrido un error inesperado",error));
        });
      });
    } */

  //EJEMPLO DE USO DEL METODO GET 
  /* async getRespuestas( entidad : string ) {
    return new Promise(async (resolve,reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.user.token
      })
       
      this.http.get<ServerMessage>(this.baseURL + 'user/obtener-respuestas',{ headers: headers }).subscribe((response : ServerMessage)=>{
        resolve(response);
      },(error)=>{
        reject(error)
      });
    });
  } */

  //EJEMPLO DE USO DEL METODO POST 
  /* async saveRespuestas(respuestas : any){
    return new Promise((resolve,reject)=>{
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.user.token
      });

      this.http.post<ServerMessage>(this.baseURL + 'user/save-respuestas',respuestas,{ headers: headers }).subscribe((response : ServerMessage)=>{
        resolve(response);
      },(error)=>{
        reject(error)
      });
    })
  } */


  /* Obtiene los datos del dashboard antiguo de gas
  *  
  */

  getAllOrganizationDevices(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })

      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-devices-byOrganization/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  getAllOrganizationRegions(idOrganization: number): Promise<ServerMessage> {
    
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-regions/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // Get all zones
  getAllOrganizationZones(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-zones-organization/' +idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  // Get all stations
  getAllOrganizationStations(idOrganization: number): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'organization/organization-data/find-stations-byOrganization/' + idOrganization, { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  } 

  // Get all regions
  getAllRegions(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-regions', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }

  // Get all zones
  getAllZones(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-zones', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }
  
  // Get all stations
  getAllStations(): Promise<ServerMessage> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.token
      })
      this.http.get<ServerMessage>(this.baseURL + 'administrator/get-all-stations', { headers: headers }).subscribe((response: ServerMessage) => {
        resolve(response);
      }, (error) => {
        reject(error)
      });
    })
  }  
}
