<!-- 
  -- Main wrapper (style you can find in pages.scss)
  -->
<div
    id="main-wrapper"
    data-layout="vertical"
    data-sidebartype="full"
    [ngClass]="{'show-sidebar': showMobileMenu}"
    [dir]="options.dir"
    [attr.data-theme]="options.theme"
    [attr.data-layout]="options.layout"
    [attr.data-sidebartype]="options.sidebartype"
    [attr.data-sidebar-position]="options.sidebarpos"
    [attr.data-header-position]="options.headerpos"
    [attr.data-boxed-layout]="options.boxed"
>
    <!-- 
      -- Topbar header (style you can find in topbar.scss)
      -->
    <header class="topbar" [attr.data-navbarbg]="options.navbarbg">
        <nav class="d-flex top-navbar navbar-expand-md" [ngClass]="(options.navbarbg=='skin6')?'navbar-light':'navbar-dark'">
            <div class="navbar-header" [ngClass]="(expandLogo)?'expand-logo':''" [attr.data-logobg]="options.logobg">
                <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-md-none" href="javascript:void(0)">
                    <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
                </a>
                <!-- 
                  -- Logo
                  -->
                <a class="navbar-brand center-content" href="/">
                    <b class="logo-icon">
                        <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" style="height: 40px;"/>
                        <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" style="height: 40px;"/>
                    </b>
                    <span class="logo-text">
                        <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" />
                        <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
                    </span>
                </a>
                <a
                    class="topbartoggler d-block d-md-none waves-effect waves-light"
                    href="javascript:void(0)"
                    (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                >
                    <i class="ti-more"></i>
                </a>
            </div>
            <div class="navbar-collapse collapse" id="navbarSupportedContent" [attr.data-navbarbg]="options.navbarbg" [ngbCollapse]="!isCollapsed">
                <app-navigation-production (toggleSidebar)="toggleSidebarType()" class="w-100"></app-navigation-production>
            </div>
        </nav>
    </header>
    
    <!-- 
      --  Left Sidebar (style you can find in sidebar.scss)
      -->
    <aside class="left-sidebar" [attr.data-sidebarbg]="options.sidebarbg" (mouseover)="Logo()" (mouseout)="Logo()">
        <div class="scroll-sidebar" [perfectScrollbar]="config">
            <app-sidebar-production></app-sidebar-production>
        </div>
    </aside>
    <div class="page-wrapper">
        <app-breadcrumb></app-breadcrumb>
        <div class="container-fluid">
            <router-outlet></router-outlet>
        </div>
        <footer class="footer text-center text-gray-300">All Rights Reserved by ingmulti.com.</footer>
    </div>
    <div class="chat-windows"></div>
</div>