import { RouteInfo } from './sidebar-admin.metadata';

export const ADMINMENU: RouteInfo[] = [
    {
        path: '/dashboard-admin/home',
        title: 'Inicio',
        icon: 'fas fa-home',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-admin/home',
        title: 'Dashboards',
        icon: 'fa fa-tachometer',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/dashboard-admin/superadmin-water',
                title: 'Agua',
                icon: 'fa fa-tint',
                class: '',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/superadmin-gas',
                title: 'Gas',
                icon: 'icon-Gas-Pump',
                class: '',
                extralink: false,
                submenu: []
            },
        ]
    },
    {
        path: '/dashboard-admin/organization',
        title: 'Mi Organización',
        icon: 'fab fa-houzz',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/dashboard-admin/organization-data',
                title: 'Información',
                icon: 'fas fa-info',//icon-Posterous , fas fa-birthday-cake
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/organization-users',
                title: 'Usuarios',
                icon: 'fas fa-users',
                class: 'has-arrow',
                extralink: false,
                submenu: [{
                    path: '/dashboard-admin/organization-clients-data',
                    title: 'Clientes',
                    icon: 'fas fa-user-alt',//icon-Posterous , fas fa-birthday-cake
                    class: 'padding-submenu',
                    extralink: false,
                    submenu: []
                },{
                    path: '/dashboard-admin/organization-technician-data',
                    title: 'Técnicos',
                    icon: 'fas fa-user-cog',//icon-Posterous , fas fa-birthday-cake
                    class: 'padding-submenu',
                    extralink: false,
                    submenu: []
                },{
                    path: '/dashboard-admin/organization-counters-data',
                    title: 'Contadores',
                    icon: 'fas fa-user-tie',//icon-Posterous , fas fa-birthday-cake
                    class: 'padding-submenu',
                    extralink: false,
                    submenu: []
                },{
                    path: '/dashboard-admin/organization-warehouses-data',
                    title: 'Almacén',
                    icon: 'fas fa-user-tag',//icon-Posterous , fas fa-birthday-cake
                    class: 'padding-submenu',
                    extralink: false,
                    submenu: []
                },]
            },
            {
                path: '/dashboard-admin/admin-organization/devices-list',
                title: 'Dispositivos',
                icon: 'fas fa-microchip',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            /* {
                path: '/dashboard-admin/organization-agenda',
                title: 'Servicios',
                icon: 'fas fa-calendar-week',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            }, */
        ]
    },
    {
        path: '/dashboard-admin/payments',
        title: 'Pagos',
        icon: 'fas fa-wallet',
        class: 'has-arrow',
        extralink: false,
        submenu: [
            {
                path: '/dashboard-admin/payments/subscription-activations',
                title: 'Activaciones',
                icon: 'fas fa-money-check-edit',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/payments/subscription-payments',
                title: 'Suscripciones',
                icon: 'fas fa-user-chart',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            {
                path: '/dashboard-admin/payments/bills',
                title: 'Facturas',
                icon: 'fas fa-file-invoice',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            },
            /* {
                path: '/dashboard-admin/organization-agenda',
                title: 'Servicios',
                icon: 'fas fa-calendar-week',
                class: 'padding-submenu',
                extralink: false,
                submenu: []
            }, */
        ]
    },
    {
        path: '/dashboard-admin/organizations',
        title: 'Organizaciones',
        icon: 'fas fa-city',
        extralink: false,
        class: '',
        submenu: [],
    },
    {
        path: '/dashboard-admin/users',
        title: 'Usuarios',
        icon: 'fas fa-users',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-admin/devices',
        title: 'Dispositivos',
        icon: 'fas fa-microchip',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-admin/assignments',
        title: 'Asignaciones',
        icon: 'fas fa-repeat',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-admin/apn-catalog',
        title: 'APN´s',
        icon: 'fas fa-signal',
        class: '',
        extralink: false,
        submenu: []
    },
    {
        path: '/dashboard-admin/account-setting',
        title: 'Ajustes de la cuenta',
        icon: 'fas fa-cogs',
        class: '',
        extralink: false,
        submenu: []
    },
];
