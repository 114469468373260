import { Component, AfterViewInit, EventEmitter, Output } from '@angular/core';
import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig
} from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { DataSessionService } from '../../../services/dataSession/data-session.service';
declare var $: any;

@Component({
  selector: 'app-navigation-public',
  templateUrl: './navigation-public.component.html',
  styleUrls: ['./navigation-public.component.scss']
})
export class NavigationPublicComponent implements AfterViewInit {
  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;

  constructor(public dataSessionService: DataSessionService,private modalService: NgbModal) {}

  ngAfterViewInit() {}

  logOut(){
    this.dataSessionService.logOut();
  }
}
