import { User } from '../../../../classes/user.class';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSessionService } from '../../../../services/dataSession/data-session.service';
import { ApiDataService } from '../../../../services/apiData/api-data.service';
import { ServerMessage } from '../../../../classes/serverMessage.class';
import { UtilitiesService } from '../../../../services/utilities/utilities.service';
//MENUS
import { ORGANIZATIONMENUAGUA, ORGANIZATIONMENUGAS } from './menu-items';
import { LoggedResponse } from 'src/app/classes/loggedResponse.class';

declare var $: any;

@Component({
  selector: 'app-sidebar-organization',
  templateUrl: './sidebar-organization.component.html',
  styleUrls: ['./sidebar-organization.component.scss']
})
export class SidebarOrganizationComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  public sidebarNavItemsOrganization: any[];

  constructor(
    public dataSessionService: DataSessionService, private utilitiesService: UtilitiesService
  ) { }

  
  ngOnInit() {

    this.dataSessionService.checkLogin((logedResponse: LoggedResponse) => {
      //console.log(logedResponse);

      // console.log(localStorage.getItem('organizationType'));

      if(parseInt(localStorage.getItem('organizationType')) == 1){
        this.sidebarNavItemsOrganization = ORGANIZATIONMENUGAS;
      }
      else if(parseInt(localStorage.getItem('organizationType')) == 2){
        this.sidebarNavItemsOrganization = ORGANIZATIONMENUAGUA;
      }

      if (this.dataSessionService.user.idRole == 1 || this.dataSessionService.user.idRole == 2) {                                              //1 = Root admin
        //console.log("Root admin");

        //this.utilitiesService.showSuccessToast(response.message, "Éxito!");
        //this.dataSessionService.navigateByUrl("/dashboard-admin/home");
      } else if (this.dataSessionService.user.idRole == 2) {       
        console.log(this.dataSessionService.user.idOrganization)
        //2 = Organization Admin
        console.log("Organization Admin");
        this.dataSessionService.navigateByUrl("/dashboard-organization/home");
      } else if (this.dataSessionService.user.idRole == 3) {                                              //3 = Warehouse user 
        console.log("Warehouse user ");
        this.dataSessionService.navigateByUrl("/dashboard-warehouse/home");
      } else if (this.dataSessionService.user.idRole == 4) {                                              //4 = Conta
        console.log("Conta");
        this.dataSessionService.navigateByUrl("/dashboard-counter/home");
      } else if (this.dataSessionService.user.idRole == 5) {                                              //5 = Driver
        console.log("Driver");
        this.dataSessionService.navigateByUrl("/dashboard-driver/home");
      } else if (this.dataSessionService.user.idRole == 6) {                                              //6 = Technician
        console.log("Technician");
        this.dataSessionService.navigateByUrl("/dashboard-technician/home");
      } else if (this.dataSessionService.user.idRole == 7) {                                              //7 = Final User
        console.log("Final User");
        this.utilitiesService.showInfoToast( "Aun no se cuenta con este servicio");
        this.dataSessionService.navigateByUrl("/login");
      } else {
        this.utilitiesService.showErrorToast("Usuario desconocido.", "Error!");
        this.dataSessionService.logOut();
      }
    }, (noLoginResponse: LoggedResponse) => {
      //console.log(noLoginResponse);
      this.dataSessionService.logOut();
    });

    // console.log(this.sidebarNavItemsOrganization);
  }

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  // End open close

  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }
}
