<section class="max-width-page p-t-120 p-b-50">
    <h3 class="blu-20 fw-400">Preguntas Frecuentes</h3>
    <h4 class="blu-16">Title</h4>
    <p class="black-16 justify-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit qui dolorum
        architecto voluptatem minus debitis
        dolore iure, assumenda, at accusantium quis. Expedita, autem maiores pariatur temporibus iure earum ea
        quaerat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt deleniti, vero consectetur, iste
        temporibus reiciendis aliquid commodi consequuntur quod culpa ipsum quidem possimus in doloribus quo magni
        cumque itaque dolores. Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est, assumenda ea
        nesciunt dolorum ipsum nam sequi ratione. Corrupti eveniet, sint repudiandae ipsam voluptas obcaecati
        mollitia deleniti nemo fuga laborum! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque,
        distinctio quas numquam in, praesentium libero et laboriosam deleniti earum, rem ipsum recusandae doloremque
        perspiciatis molestias omnis repellendus. Minus, facilis hic! Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sapiente repellendus repellat incidunt maxime repudiandae obcaecati molestiae dicta eum
        harum eaque voluptatibus enim, dignissimos amet excepturi voluptatum facere, pariatur dolore natus!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit qui dolorum architecto voluptatem minus debitis
        dolore iure, assumenda, at accusantium quis. Expedita, autem maiores pariatur temporibus iure earum ea
        quaerat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt deleniti, vero consectetur, iste
        temporibus reiciendis aliquid commodi consequuntur quod culpa ipsum quidem possimus in doloribus quo magni
        cumque itaque dolores. Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est, assumenda ea
        nesciunt dolorum ipsum nam sequi ratione. Corrupti eveniet, sint repudiandae ipsam voluptas obcaecati
        mollitia deleniti nemo fuga laborum! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque,
        distinctio quas numquam in, praesentium libero et laboriosam deleniti earum, rem ipsum recusandae doloremque
        perspiciatis molestias omnis repellendus. Minus, facilis hic! Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sapiente repellendus repellat incidunt maxime repudiandae obcaecati molestiae dicta eum
        harum eaque voluptatibus enim, dignissimos amet excepturi voluptatum facere, pariatur dolore natus!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit qui dolorum architecto voluptatem minus debitis
        dolore iure, assumenda, at accusantium quis. Expedita, autem maiores pariatur temporibus iure earum ea
        quaerat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt deleniti, vero consectetur, iste
        temporibus reiciendis aliquid commodi consequuntur quod culpa ipsum quidem possimus in doloribus quo magni
        cumque itaque dolores. Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est, assumenda ea
        nesciunt dolorum ipsum nam sequi ratione. Corrupti eveniet, sint repudiandae ipsam voluptas obcaecati
        mollitia deleniti nemo fuga laborum! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque,
        distinctio quas numquam in, praesentium libero et laboriosam deleniti earum, rem ipsum recusandae doloremque
        perspiciatis molestias omnis repellendus. Minus, facilis hic! Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sapiente repellendus repellat incidunt maxime repudiandae obcaecati molestiae dicta eum
        harum eaque voluptatibus enim, dignissimos amet excepturi voluptatum facere, pariatur dolore natus!
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit qui dolorum architecto voluptatem minus debitis
        dolore iure, assumenda, at accusantium quis. Expedita, autem maiores pariatur temporibus iure earum ea
        quaerat. Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sunt deleniti, vero consectetur, iste
        temporibus reiciendis aliquid commodi consequuntur quod culpa ipsum quidem possimus in doloribus quo magni
        cumque itaque dolores. Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta est, assumenda ea
        nesciunt dolorum ipsum nam sequi ratione. Corrupti eveniet, sint repudiandae ipsam voluptas obcaecati
        mollitia deleniti nemo fuga laborum! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque,
        distinctio quas numquam in, praesentium libero et laboriosam deleniti earum, rem ipsum recusandae doloremque
        perspiciatis molestias omnis repellendus. Minus, facilis hic! Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Sapiente repellendus repellat incidunt maxime repudiandae obcaecati molestiae dicta eum
        harum eaque voluptatibus enim, dignissimos amet excepturi voluptatum facere, pariatur dolore natus!</p>
</section>